import React, { Component } from 'react';
import { Card, CardBody, CardTitle, Col, FormGroup, Row } from 'reactstrap';
import { connect } from "react-redux";

import Backdrop from '../Common/Backdrop';
import * as editFormControls from '../../helpers/editFormControls';

import classes from './Pages.module.css';

const selColPrefix = "selectedColumn_";

class ColumnSelectionForm extends Component {

    constructor(props) {
        super(props);

        this.state = {
        };

        this.onChange = this.onChange.bind(this);
    }

    generateSelectedColumnString(updatedStateField, updatedStateFieldValue) {
        // Based on the recently updated state, generates a selected column string
        // e.g. "customerName,invoiceNo,dueDate,amountWithVAT,unallocatedAmount,currencyCode,lastSendingDate,lastReminderSendingDate"

        const allStateKeys = Object.keys(this.state);
        if (!allStateKeys.includes(updatedStateField)) {
            allStateKeys.push(updatedStateField);
        }

        let selectedColumnString = "";
        for (let i in allStateKeys) {
            const thisKey = allStateKeys[i];
            if (thisKey.startsWith(selColPrefix)) {
                let doInclude = false;
                if (updatedStateField === thisKey) {
                    doInclude = updatedStateFieldValue;
                } else {
                    doInclude = this.state[thisKey];
                }
                if (doInclude) {
                    const thisColumnID = thisKey.substring(selColPrefix.length);
                    selectedColumnString = selectedColumnString.length ? (selectedColumnString + "," + thisColumnID) : thisColumnID;
                }
            }
        }
        this.props.applySelectedColumns(selectedColumnString);
    }

    onChange(e) {
        const thisObjectName = e.target.name;
        let thisValue;
        if (e.target.type == "checkbox") {
            thisValue = e.target.checked;
        } else {
            thisValue = e.target.value;
        }
        this.setState({
            [thisObjectName]: thisValue
        });
        this.generateSelectedColumnString(thisObjectName, thisValue);
    }

    componentDidMount() {
        if (this.props.selectedColumns && this.props.selectedColumns.length) {
            for (let i in this.props.selectedColumns) {
                const thisColumn = this.props.selectedColumns[i];
                if (thisColumn) {
                    const stateFieldName = selColPrefix + thisColumn.dataField;
                    this.setState({
                        [stateFieldName]: true
                    });
                }
            }
        }
    }

    render() {

        const availableColumnKeys = Object.keys(this.props.availableColumns);

        const columnSelectionControls =
            availableColumnKeys.map((key, i) => {
                const thisColumn = this.props.availableColumns[key];
                const stateFieldName = selColPrefix + thisColumn.dataField;
                return (
                    <FormGroup
                        className="mb-3"
                        row
                        key={i}
                        style={{ height: "1.5rem" }}
                    >
                        <Col lg="2">
                            {editFormControls.checkboxControlWithoutLabel(stateFieldName, this.onChange, this.state[stateFieldName])}
                        </Col>
                        <Col lg="10">
                            <div style={{ display: "flex", height: "100%", alignItems: "center" }}>
                                &nbsp;
                                {thisColumn.text}
                            </div>
                        </Col>
                    </FormGroup>
                );
            }
            );

        return (

            <div>
                <Backdrop
                    show
                    onClick={this.props.onClose}
                    zIndex={10002}  // To show on top of SelectCustomerInvoices form (for allocation), which has Backdrop with default zIndex of 10000 and whose own zIndex is 10001
                />
                <div>
                    <div
                        className={classes.ColumnSelectionForm}
                    >
                        <Card
                            // className={classes.ColumnSelectionFormCard} // For some reason this does not work 
                            style={{
                                height: "100%",
                                overflowX: "hidden",
                                overflowY: "auto"
                            }}
                        >
                            <CardTitle>
                                <div className={classes.CardTitleDiv}>
                                    <div className={classes.CardTitleSubDiv}>
                                        Select columns
                                    </div>
                                    <div className={classes.CloseButtonDiv}>
                                        <p className={classes.CloseButtonDivP}>
                                            <i
                                                className="bx bx-x"
                                                onClick={this.props.onClose}
                                                style={{ cursor: "pointer" }}
                                            />
                                        </p>
                                    </div>
                                </div>
                                <br />
                            </CardTitle>
                            <CardBody
                                className={classes.ColumnSelectionFormCardBody}
                            >

                                {columnSelectionControls}

                            </CardBody>
                        </Card>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = () => ({
});

const mapDispatchToProps = dispatch => ({
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ColumnSelectionForm);