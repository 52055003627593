import React, { Component } from 'react';
import { Button, Card, CardBody, CardTitle, Col, FormGroup, Label, Row } from 'reactstrap';
import { connect } from "react-redux";

import * as actionsTimelog from '../../store/Timelog/actions';
import * as actionsTimelogTimer from '../../store/TimelogTimer/actions';
import Backdrop from '../Common/Backdrop';
import * as dateAndTimeUtils from '../../helpers/dateAndTimeUtils';
import * as editFormControls from '../../helpers/editFormControls';

import classes from './Pages.module.css';

class AddTimelogEntry extends Component {

    constructor(props) {
        super(props);

        this.state = {
            taskId: null,
            date: (new Date()).toISOString(),
            hours: 0,
            minutes: 0,
            comment: ""
        };

        this.onChange = this.onChange.bind(this);
    }

    onChange(e) {
        const thisObjectName = e.target.name;
        let thisValue;
        if (e.target.type == "checkbox") {
            thisValue = e.target.checked;
        } else {
            thisValue = e.target.value;
        }
        if (thisObjectName === "dateFrom" && thisValue > this.state.dateTo) {
            this.setState({
                dateTo: thisValue
            });
        }
        this.setState({
            [thisObjectName]: thisValue
        });
    }

    updateValuesFromProps = () => {
        if (this.props.timer) {
            this.setState({
                taskId: this.props.timer.taskId
            });
            if (this.props.timer.hoursPassed) {
                const frontendTimerHoursPassed = this.props.frontendTimerHoursPassed ? this.props.frontendTimerHoursPassed : 0
                const timeInterval = dateAndTimeUtils.hoursToTimeInterval(this.props.timer.hoursPassed + frontendTimerHoursPassed);
                this.setState({
                    hours: timeInterval.hours,
                    minutes: timeInterval.minutes
                });
            }
            if (this.props.timer.comment) {
                this.setState({
                    comment: this.props.timer.comment
                })
            }
        }
    }

    componentDidMount() {
        this.updateValuesFromProps();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.timer !== this.props.timer) {
            this.updateValuesFromProps();
        }
    }

    sleep(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    onAddTimelogEntry = () => {
        const hoursLogged = dateAndTimeUtils.timeIntervalToHours({ hours: this.state.hours, minutes: this.state.minutes });
        const date = this.state.date.substring(0, 10);
        if (this.props.timer.id) {
            this.props.onAddTimelogEntryFromTimer(
                this.state.taskId,
                this.state.comment,
                date,
                hoursLogged
            );
            // Stopping a timer returns a list of active timers, not a list of the task's timelogs.
            // Therefore we get a list of task's timelogs, and in addition do it after a small delay
            // to ensure the new timelog is already in the list 
            // (as of 2023.08.17 the experiments have shown that a 100ms delay is fine).
            setTimeout(() => {
                this.props.onGetTimelogsByTaskId(this.state.taskId);
            }, 100);
        } else {
            this.props.onAddTimelogEntryWithoutTimer({
                taskId: this.state.taskId,
                date: date,
                hoursLogged: hoursLogged,
                userLoggedId: this.props.userData.userId,
                comment: this.state.comment
            });
        }
        this.props.onClose();
    }

    onDeleteTimer = () => {
        this.props.onDeleteTimer(
            this.state.taskId
        );
        this.props.onClose();
    }

    render() {

        return (

            <div>
                <Backdrop
                    show
                    onClick={this.props.onClose}
                >
                </Backdrop>
                <div
                    className={classes.RequestBankStatementContainer}
                >
                    <Card
                        // className={classes.PageCard} // See ColummSelectionForm, SelectCustomerInvoices etc
                        style={{
                            height: "100%",
                            overflowX: "auto",
                            overflowY: "auto"
                        }}
                    >
                        <CardTitle>
                            <div className={classes.CardTitleDiv}>
                                <div className={classes.CardTitleSubDiv}>
                                    Add timelog entry
                                </div>
                                <div className={classes.CloseButtonDiv}>
                                    <p className={classes.CloseButtonDivP}>
                                        <i
                                            className="bx bx-x"
                                            onClick={this.props.onClose}
                                            style={{ cursor: "pointer" }}
                                        />
                                    </p>
                                </div>
                            </div>
                            <br />
                        </CardTitle>
                        <CardBody>

                            <FormGroup className="mb-4" row>
                                <Label
                                    htmlFor="hours"
                                    className={editFormControls.labelClass}
                                >
                                    Time
                                </Label>
                                <Col lg={editFormControls.valueColSize / 2}>
                                    {editFormControls.numberControlWithMinMaxWithoutLabel("hours", "Hours", this.onChange, this.state.hours, 1, 0, 24)}
                                </Col>
                                <Col xs="auto">
                                    <span style={{ fontSize: "1.5rem" }}>
                                        :
                                    </span>
                                </Col>
                                <Col lg={editFormControls.valueColSize / 2}>
                                    {editFormControls.numberControlWithMinMaxWithoutLabel("minutes", "Minutes", this.onChange, this.state.minutes, 1, 0, 59)}
                                </Col>
                            </FormGroup>
                            {editFormControls.dateControl("date", "Date", this.onChange, this.state.date)}
                            {editFormControls.textAreaControl("comment", "Comment", this.onChange, this.state.comment)}
                            <Button
                                color="primary"
                                onClick={this.onAddTimelogEntry}
                                disabled={!parseInt(this.state.hours) && !parseInt(this.state.minutes)}
                            >
                                Add
                                {" "}
                                {this.props.requesting ? editFormControls.buttonSpinner() : null}
                            </Button>

                            &nbsp;

                            {this.props.timer.id && editFormControls.deleteButton(false, this.onDeleteTimer, true)}

                        </CardBody>
                    </Card>
                </div>
            </div>
        );
    }
}

const mapStateToProps = ({ userLogin }) => ({
    userData: userLogin.userData
});

const mapDispatchToProps = dispatch => ({
    onAddTimelogEntryFromTimer: (taskId, comment, date, hoursLogged) => dispatch(actionsTimelogTimer.timelogTimerChangeStatus({ action: "STOP", taskId, comment, date, hoursLogged })),
    onAddTimelogEntryWithoutTimer: (timelog) => dispatch(actionsTimelog.timelogCreate(timelog)),
    onDeleteTimer: (taskId) => dispatch(actionsTimelogTimer.timelogTimerChangeStatus({ action: "DELETE", taskId })),
    onGetTimelogsByTaskId: (taskId) => dispatch(actionsTimelog.timelogGetByTask(taskId))
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AddTimelogEntry);