import { call, put, takeLatest } from 'redux-saga/effects';

import * as actions from "./actions";
import * as actionTypes from './actionTypes';
import * as endpointsFrontend from '../../definitions/endpoints/endpoints-frontend';
import * as endpointsBackend from '../../definitions/endpoints/endpoints-backend';
import * as rest from "../../helpers/restHelper";
import * as sagaHelper from "../../helpers/sagaHelper";

function* getAllPaymentsSaga() {
	yield put(actions.paymentGetInProcess());
	try {
		const response = yield call(
			rest.get,
			endpointsBackend.PAYMENT_GET_ALL
		);

		yield put(actions.paymentGetSuccess(sagaHelper.getEntriesFromResponseData(response.data)));
	} catch (e) {
		yield put(actions.paymentGetError(e));
	}
}

function* getAllErroneousPaymentsSaga() {
	yield put(actions.paymentGetInProcess());
	try {
		const response = yield call(
			rest.get,
			endpointsBackend.PAYMENT_GET_ERRONEOUS
		);

		yield put(actions.paymentGetSuccess(sagaHelper.getEntriesFromResponseData(response.data)));
	} catch (e) {
		yield put(actions.paymentGetError(e));
	}
}

function* getAllSignedPaymentsSaga() {
	yield put(actions.paymentGetInProcess());
	try {
		const response = yield call(
			rest.get,
			endpointsBackend.PAYMENT_GET_PROCESSED
		);

		yield put(actions.paymentGetSuccess(sagaHelper.getEntriesFromResponseData(response.data)));
	} catch (e) {
		yield put(actions.paymentGetError(e));
	}
}

function* getAllUnsignedPaymentsSaga() {
	yield put(actions.paymentGetInProcess());
	try {
		const response = yield call(
			rest.get,
			endpointsBackend.PAYMENT_GET_UNSIGNED
		);

		yield put(actions.paymentGetSuccess(sagaHelper.getEntriesFromResponseData(response.data)));
	} catch (e) {
		yield put(actions.paymentGetError(e));
	}
}

function* getAllUnallocatedPaymentsSaga() {
	yield put(actions.paymentGetInProcess());
	try {
		const response = yield call(
			rest.get,
			endpointsBackend.PAYMENT_GET_UNALLOCATED
		);

		yield put(actions.paymentGetSuccess(sagaHelper.getEntriesFromResponseData(response.data)));
	} catch (e) {
		yield put(actions.paymentGetError(e));
	}
}

function* getAllSentToBankSignedPaymentsSaga() {
	yield put(actions.paymentGetInProcess());
	try {
		const response = yield call(
			rest.get,
			endpointsBackend.PAYMENT_GET_SENT_TO_BANK_SIGNED
		);

		yield put(actions.paymentGetSuccess(sagaHelper.getEntriesFromResponseData(response.data)));
	} catch (e) {
		yield put(actions.paymentGetError(e));
	}
}

function* getAllSentToBankUnsignedPaymentsSaga() {
	yield put(actions.paymentGetInProcess());
	try {
		const response = yield call(
			rest.get,
			endpointsBackend.PAYMENT_GET_SENT_TO_BANK_UNSIGNED
		);

		yield put(actions.paymentGetSuccess(sagaHelper.getEntriesFromResponseData(response.data)));
	} catch (e) {
		yield put(actions.paymentGetError(e));
	}
}

function* getPaymentByIdSaga({ id }) {
	yield put(actions.paymentGetInProcess());
	try {
		const response = yield call(
			rest.get,
			endpointsBackend.PAYMENT_GET_BY_ID.replace("{paymentId}", id)
		);

		yield put(actions.paymentGetSuccess([{ ...response.data }]));
	} catch (e) {
		yield put(actions.paymentGetError(e));
	}
}

function* createPaymentSaga({ payment, history }) {
	try {
		const response = yield call(
			rest.post,
			endpointsBackend.PAYMENT_BULK_CREATE,
			payment
		);
		if (history) {
			history.push(endpointsFrontend.PAYMENT_BROWSE_PREPARED);
		}
		yield put(actions.paymentCreateSuccess(response.data));
	} catch (e) {
		yield put(actions.paymentCreateError(e));
	}
}

function* deletePaymentSaga({ id, history }) {
	try {
		yield call(
			rest.del,
			endpointsBackend.PAYMENT_GET_BY_ID.replace("{paymentId}", id)
		);
		yield put(actions.paymentDeleteSuccess());
		history.push(endpointsFrontend.PAYMENT_BROWSE_PREPARED);
	} catch (e) {
		yield put(actions.paymentDeleteError(e));
	}
}

function* deletePaymentListSaga({ paymentsToDelete, history }) {
	try {
		yield call(
			rest.del,
			endpointsBackend.PAYMENT_CREATE_OR_UPDATE,
			{ idList: paymentsToDelete }
		);
		yield put(actions.paymentDeleteSuccess());
		// yield call(getAllPreparedPaymentsSaga);
		//history.push(endpointsFrontend.PAYMENT_BROWSE_PREPARED);
	} catch (e) {
		yield put(actions.paymentDeleteError(e));
	}
}

function* getPaymentTotalSaga({ selectedPayments }) {
	try {
		const response = yield call(
			rest.post,
			endpointsBackend.PAYMENT_GET_TOTAL_BY_ID_LIST,
			{ idList: selectedPayments }
		);

		yield put(actions.paymentGetTotalSuccess(response.data));
	} catch (e) {
		yield put(actions.paymentGetTotalError(e));
	}
}

function* getSimulatedPaymentTotalSaga({ selectedInvoices, bankAccountId, currencyId, doGroupPayments, history }) {
	try {
		const response = yield call(
			rest.post,
			endpointsBackend.PAYMENT_GET_TOTAL_FROM_SIMULATED,
			{
				supplierInvoiceIds: selectedInvoices,
				bankAccountId: bankAccountId,
				currencyId: currencyId,
				doGroupPayments: doGroupPayments
			}
		);
		yield put(actions.paymentGetTotalSuccess(response.data));
	} catch (e) {
		yield put(actions.paymentGetTotalError(e));
	}
}

function* getPreparedPaymentLinesSaga() {
	try {
		const response = yield call(
			rest.get,
			endpointsBackend.PAYMENT_LINE_GET_PREPARED
		);
		yield put(actions.paymentLineGetSuccess(response.data));
	} catch (e) {
		yield put(actions.paymentLineGetError(e));
	}
}

function* PaymentSaga() {
	yield (takeLatest(actionTypes.PAYMENT_CREATE, createPaymentSaga));
	// yield (takeLatest(actionTypes.PAYMENT_CREATE_FROM_INVOICES, createPaymentsFromInvoicesSaga));
	yield (takeLatest(actionTypes.PAYMENT_DELETE, deletePaymentSaga));
	yield (takeLatest(actionTypes.PAYMENT_DELETE_LIST, deletePaymentListSaga));
	yield (takeLatest(actionTypes.PAYMENT_GET_ALL, getAllPaymentsSaga));
	yield (takeLatest(actionTypes.PAYMENT_GET_ERRONEOUS, getAllErroneousPaymentsSaga));
	yield (takeLatest(actionTypes.PAYMENT_GET_SIGNED, getAllSignedPaymentsSaga));
	yield (takeLatest(actionTypes.PAYMENT_GET_UNALLOCATED, getAllUnallocatedPaymentsSaga));
	yield (takeLatest(actionTypes.PAYMENT_GET_UNSIGNED, getAllUnsignedPaymentsSaga));
	yield (takeLatest(actionTypes.PAYMENT_GET_SENT_TO_BANK_SIGNED, getAllSentToBankSignedPaymentsSaga));
	yield (takeLatest(actionTypes.PAYMENT_GET_SENT_TO_BANK_UNSIGNED, getAllSentToBankUnsignedPaymentsSaga));
	yield (takeLatest(actionTypes.PAYMENT_GET_BY_ID, getPaymentByIdSaga));
	yield (takeLatest(actionTypes.PAYMENT_GET_TOTAL, getPaymentTotalSaga));
	yield (takeLatest(actionTypes.PAYMENT_GET_TOTAL_FROM_SIMULATED, getSimulatedPaymentTotalSaga));
	yield (takeLatest(actionTypes.PAYMENT_LINE_GET_PREPARED, getPreparedPaymentLinesSaga));
}

export default PaymentSaga;