import { call, put, takeEvery } from 'redux-saga/effects';

import * as actions from "./actions";
import * as actionTypes from './actionTypes';
import * as endpointsBackend from '../../definitions/endpoints/endpoints-backend';
import * as endpointsFrontend from '../../definitions/endpoints/endpoints-frontend';
import * as rest from "../../helpers/restHelper";
import * as sagaHelper from "../../helpers/sagaHelper";

function* getAllCM20PaymentsSaga() {
	yield put(actions.cm20PaymentGetInProcess());
	try {
		const response = yield call(
			rest.get,
			endpointsBackend.CM20PAYMENT_GET_ALL
		);

		const cm20Payments = [];
		for (let key in response.data) {
			cm20Payments.push({
				...response.data[key]
			});
		}
		yield put(actions.cm20PaymentGetSuccess(cm20Payments));
	} catch (e) {
		yield put(actions.cm20PaymentGetError(e));
	}
}

function* getAllErroneousCM20PaymentsSaga() {
	yield put(actions.cm20PaymentGetInProcess());
	try {
		const response = yield call(
			rest.get,
			endpointsBackend.CM20PAYMENT_GET_ERRONEOUS
		);

		const cm20Payments = [];
		for (let key in response.data) {
			cm20Payments.push({
				...response.data[key]
			});
		}
		yield put(actions.cm20PaymentGetSuccess(cm20Payments));
	} catch (e) {
		yield put(actions.cm20PaymentGetError(e));
	}
}

function* getAllSignedCM20PaymentsSaga() {
	yield put(actions.cm20PaymentGetInProcess());
	try {
		const response = yield call(
			rest.get,
			endpointsBackend.CM20PAYMENT_GET_SIGNED
		);

		const cm20Payments = [];
		for (let key in response.data) {
			cm20Payments.push({
				...response.data[key]
			});
		}
		yield put(actions.cm20PaymentGetSuccess(cm20Payments));
	} catch (e) {
		yield put(actions.cm20PaymentGetError(e));
	}
}

function* getAllUnsignedCM20PaymentsSaga() {
	yield put(actions.cm20PaymentGetInProcess());
	try {
		const response = yield call(
			rest.get,
			endpointsBackend.CM20PAYMENT_GET_UNSIGNED
		);

		const cm20Payments = [];
		for (let key in response.data) {
			cm20Payments.push({
				...response.data[key]
			});
		}
		yield put(actions.cm20PaymentGetSuccess(cm20Payments));
	} catch (e) {
		yield put(actions.cm20PaymentGetError(e));
	}
}

function* getCM20PaymentByIdSaga({ id }) {
	yield put(actions.cm20PaymentGetInProcess());
	try {
		const response = yield call(
			rest.get,
			endpointsBackend.CM20PAYMENT_GET_BY_ID.replace("{cm20PaymentId}", id)
		);

		yield put(actions.cm20PaymentGetSuccess([{ ...response.data }]));
	} catch (e) {
		yield put(actions.cm20PaymentGetError(e));
	}
}

function* createCM20PaymentSaga({ cm20Payment, history }) {
	try {
		//console.log(cm20Payment);
		// As of 2022.01.11, encountered a situation when in an empty company, after creating a new currency, upon trying to create a new exchange rate
		// the frontend was somehow sending a request with currencyId = null. Could not investigate as the problem disappeared after page refresh.

		yield call(
			rest.post,
			endpointsBackend.CM20PAYMENT_CREATE_OR_UPDATE,
			cm20Payment
		);
		history.push(endpointsFrontend.CM20PAYMENT_BROWSE_UNSIGNED);
	} catch (e) {
		yield put(actions.cm20PaymentCreateError(e));
	}
}

function* deleteCM20PaymentSaga({ id, history }) {
	try {
		yield call(
			rest.del,
			endpointsBackend.CM20PAYMENT_GET_BY_ID.replace("{cm20PaymentId}", id)
		);
		history.push(endpointsFrontend.CM20PAYMENT_BROWSE_UNSIGNED);
	} catch (e) {
		yield put(actions.cm20PaymentCreateError(e));
	}
}

function* cm20PaymentSaga() {
	yield (takeEvery(actionTypes.CM20PAYMENT_CREATE, createCM20PaymentSaga));
	yield (takeEvery(actionTypes.CM20PAYMENT_DELETE, deleteCM20PaymentSaga));
	yield (takeEvery(actionTypes.CM20PAYMENT_GET_ALL, getAllCM20PaymentsSaga));
	yield (takeEvery(actionTypes.CM20PAYMENT_GET_ERRONEOUS, getAllErroneousCM20PaymentsSaga));
	yield (takeEvery(actionTypes.CM20PAYMENT_GET_SIGNED, getAllSignedCM20PaymentsSaga));
	yield (takeEvery(actionTypes.CM20PAYMENT_GET_UNSIGNED, getAllUnsignedCM20PaymentsSaga));
	yield (takeEvery(actionTypes.CM20PAYMENT_GET_BY_ID, getCM20PaymentByIdSaga));
}

export default cm20PaymentSaga;