import { call, put, takeLatest } from 'redux-saga/effects';

import * as actions from "./actions";
import * as actionTypes from './actionTypes';
import * as endpointsBackend from '../../definitions/endpoints/endpoints-backend';
import * as endpointsFrontend from '../../definitions/endpoints/endpoints-frontend';
import * as rest from "../../helpers/restHelper";
import * as sagaHelper from "../../helpers/sagaHelper"

function* createTimelogSaga({ timelog, history }) {
	try {
		const response = yield call(
			rest.post,
			endpointsBackend.TIMELOG_CREATE_OR_UPDATE,
			timelog
		);
		yield put(actions.timelogCreateSuccess(sagaHelper.getEntriesFromResponseData(response.data)));
		//history.push(endpointsFrontend.TIMELOG_BROWSE);
	} catch (e) {
		yield put(actions.timelogCreateError(e));
	}
}

function* deleteTimelogSaga({ id, history }) {
	try {
		yield call(
			rest.del,
			endpointsBackend.TIMELOG_GET_BY_ID.replace("{timelogId}", id)
		);
		yield put(actions.timelogDeleteSuccess());
		history.push(endpointsFrontend.TIMELOG_BROWSE);
	} catch (e) {
		yield put(actions.timelogDeleteError(e));
	}
}

function* getAllTimelogsSaga() {
	try {
		const response = yield call(
			rest.get,
			endpointsBackend.TIMELOG_GET_ALL
		);

		yield put(actions.timelogGetSuccess(sagaHelper.getEntriesFromResponseData(response.data)));
	} catch (e) {
		yield put(actions.timelogGetError(e));
	}
}

function* getTimelogByIdSaga({ id }) {
	try {
		const response = yield call(
			rest.get,
			endpointsBackend.TIMELOG_GET_BY_ID.replace("{timelogId}", id)
		);

		yield put(actions.timelogGetSuccess([{ ...response.data }]));
	} catch (e) {
		yield put(actions.timelogGetError(e));
	}
}

function* getTimelogsByTaskSaga({ taskId }) {
	try {
		const response = yield call(
			rest.get,
			endpointsBackend.TIMELOG_GET_ALL_BY_TASK_ID.replace("{taskId}", taskId)
		);
		yield put(actions.timelogGetSuccess(sagaHelper.getEntriesFromResponseData(response.data)));
	} catch (e) {
		yield put(actions.timelogGetError(e));
	}
}

function* timelogSaga() {
	yield (takeLatest(actionTypes.TIMELOG_CREATE, createTimelogSaga));
	yield (takeLatest(actionTypes.TIMELOG_DELETE, deleteTimelogSaga));
	yield (takeLatest(actionTypes.TIMELOG_GET_ALL, getAllTimelogsSaga));
	yield (takeLatest(actionTypes.TIMELOG_GET_BY_ID, getTimelogByIdSaga));
	yield (takeLatest(actionTypes.TIMELOG_GET_BY_TASK, getTimelogsByTaskSaga));
}

export default timelogSaga;