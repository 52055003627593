import { call, put, takeLatest } from 'redux-saga/effects';

import * as actions from "./actions";
import * as actionTypes from './actionTypes';
import * as endpointsBackend from '../../definitions/endpoints/endpoints-backend';
import * as endpointsFrontend from '../../definitions/endpoints/endpoints-frontend';
import * as hwcryptoHelper from '../../helpers/hwcryptoHelper';
import * as rest from "../../helpers/restHelper";
import * as sagaHelper from "../../helpers/sagaHelper";

// function* deletePaymentFileSaga({ id, history }) {
// 	try {
// 		yield call(
// 			rest.del,
// 			endpointsBackend.PAYMENT_FILE_GET_BY_ID.replace("{paymentFileId}", id)
// 		);
// 		yield put(actions.paymentFileDeleteSuccess());
// 		history.push(endpointsFrontend.PAYMENT_BROWSE_PREPARED);
// 	} catch (e) {
// 		yield put(actions.paymentFileDeleteError(e));
// 	}
// }

function* deletePaymentFileListSaga({ paymentFilesToDelete, history }) {
	try {
		yield call(
			rest.del,
			endpointsBackend.PAYMENT_FILE_CREATE_OR_UPDATE,
			{ idList: paymentFilesToDelete }
		);
		yield put(actions.paymentFileDeleteSuccess());
		yield call(getAllPaymentFilesSaga, { filter: "processed" });
		// history.push(endpointsFrontend.PAYMENT_BROWSE_PREPARED);
	} catch (e) {
		yield put(actions.paymentFileDeleteError(e));
	}
}

function* getAllPaymentFilesSaga({ filter }) {
	try {
		const response = yield call(
			rest.get,
			endpointsBackend.PAYMENT_FILE_GET_FILTERED.replace("{filter}", filter)
		);
		yield put(actions.paymentFileGetSuccess(sagaHelper.getEntriesFromResponseData(response.data)));
	} catch (e) {
		yield put(actions.paymentFileGetError(e));
	}
}

// function* getPaymentFileTotalSaga({ selectedPaymentFiles }) {
// 	try {
// 		const response = yield call(
// 			rest.post,
// 			endpointsBackend.PAYMENT_FILE_GET_TOTAL_BY_ID_LIST,
// 			{ idList: selectedPaymentFiles }
// 		);

// 		yield put(actions.paymentFileGetTotalSuccess(response.data));
// 	} catch (e) {
// 		yield put(actions.paymentFileGetTotalError(e));
// 	}
// }

function* refreshPaymentFileStatus({ filter }) {
	try {
		yield call(
			rest.get,
			endpointsBackend.BANK_STATEMENT_RECEIVE_ALL
		);
		yield put(actions.paymentFileRefreshStatusSuccess());
		yield call(getAllPaymentFilesSaga, { filter });
	} catch (e) {
		yield put(actions.paymentFileRefreshStatusError(e));
	}
}

function* resetFailedPaymentBulkSaga({ paymentFilesToReset, history }) {
	try {
		yield call(
			rest.post,
			endpointsBackend.PAYMENT_FILE_RESET_FAILED_BULKS,
			{ idList: paymentFilesToReset }
		);
		yield put(actions.paymentFileResetFailedBulkSuccess());
		history.push(endpointsFrontend.PAYMENT_BROWSE_PREPARED);
	} catch (e) {
		yield put(actions.paymentFileResetFailedBulkError(e));
	}
}

function* resetSignPaymentFilesSaga({ paymentFilesToReset, history }) {
	try {
		const response = yield call(
			rest.post,
			endpointsBackend.PAYMENT_FILE_SIGN_RESET,
			{
				idList: paymentFilesToReset
			}
		);
		yield put(actions.paymentFileSignResetSuccess(sagaHelper.getEntriesFromResponseData(response.data)));
	} catch (e) {
		yield put(actions.paymentFileSignError(e));
	}
}

function* signPaymentFilesSaga({ paymentFileIdList, signingCertificate, signingCertificateObject, history }) {
	try {

		let responseFinalize;

		for (let i in paymentFileIdList) {
			const responseInitRaw = yield call(
				rest.post,
				endpointsBackend.PAYMENT_FILE_SIGN_INIT,
				{
					paymentFileId: paymentFileIdList[i],
					signingCertificate: signingCertificate,
					testMode: false
				}
			);

			const signedData = yield call(
				hwcryptoHelper.signData,
				responseInitRaw.data,
				signingCertificateObject,
				false
			);

			responseFinalize = yield call(
				rest.post,
				endpointsBackend.PAYMENT_FILE_SIGN_FINALIZE,
				{
					paymentFileId: paymentFileIdList[i],
					signingCertificate: signingCertificate,
					signedDataHexString: signedData[0].signedDataHexString
				}
			);
		}

		yield put(actions.paymentFileSignSuccess(sagaHelper.getEntriesFromResponseData(responseFinalize.data)));

		//history.push(endpointsFrontend.PAYMENT_FILE_SIGN_SUCCESS);
	} catch (e) {
		yield put(actions.paymentFileSignError(e));
	}
}

function* PaymentFileSaga() {
	// yield (takeLatest(actionTypes.PAYMENT_FILE_DELETE, deletePaymentFileSaga));
	yield (takeLatest(actionTypes.PAYMENT_FILE_DELETE_LIST, deletePaymentFileListSaga));
	yield (takeLatest(actionTypes.PAYMENT_FILE_RESET_FAILED_BULK_LIST, resetFailedPaymentBulkSaga));
	yield (takeLatest(actionTypes.PAYMENT_FILE_GET, getAllPaymentFilesSaga));
	// yield (takeLatest(actionTypes.PAYMENT_FILE_GET_TOTAL, getPaymentFileTotalSaga));
	yield (takeLatest(actionTypes.PAYMENT_FILE_REFRESH_STATUS, refreshPaymentFileStatus))
	yield (takeLatest(actionTypes.PAYMENT_FILE_SIGN_INIT, signPaymentFilesSaga));
	yield (takeLatest(actionTypes.PAYMENT_FILE_SIGN_RESET, resetSignPaymentFilesSaga));
}

export default PaymentFileSaga;