export const SUPPLIER_GET_ALL = "SUPPLIER_GET_ALL";
export const SUPPLIER_GET_BY_ID = "SUPPLIER_GET_BY_ID";
export const SUPPLIER_GET_IN_PROCESS = "SUPPLIER_GET_IN_PROCESS";
export const SUPPLIER_GET_ERROR = "SUPPLIER_GET_ERROR";
export const SUPPLIER_GET_SUCCESS = "SUPPLIER_GET_SUCCESS";

export const SUPPLIER_CREATE = "SUPPLIER_CREATE";
export const SUPPLIER_CREATE_ERROR = "SUPPLIER_CREATE_ERROR";
export const SUPPLIER_CREATE_SUCCESS = "SUPPLIER_CREATE_SUCCESS";

export const SUPPLIER_DELETE = "SUPPLIER_DELETE";

