export const BANK_STATEMENT_GET_ALL = "BANK_STATEMENT_GET_ALL";
export const BANK_STATEMENT_GET_BY_ID = "BANK_STATEMENT_GET_BY_ID";
export const BANK_STATEMENT_GET_ERROR = "BANK_STATEMENT_GET_ERROR";
export const BANK_STATEMENT_GET_SUCCESS = "BANK_STATEMENT_GET_SUCCESS";

export const BANK_STATEMENT_RECEIVE = "BANK_STATEMENT_RECEIVE";
export const BANK_STATEMENT_RECEIVE_ERROR = "BANK_STATEMENT_RECEIVE_ERROR";
export const BANK_STATEMENT_RECEIVE_SUCCESS = "BANK_STATEMENT_RECEIVE_SUCCESS";

export const BANK_STATEMENT_REFRESH_ALL = "BANK_STATEMENT_REFRESH_ALL";

export const BANK_STATEMENT_REQUEST = "BANK_STATEMENT_REQUEST";
export const BANK_STATEMENT_REQUEST_ERROR = "BANK_STATEMENT_REQUEST_ERROR";
export const BANK_STATEMENT_REQUEST_SUCCESS = "BANK_STATEMENT_REQUEST_SUCCESS";

export const BANK_STATEMENT_UPLOAD = "BANK_STATEMENT_UPLOAD";
export const BANK_STATEMENT_UPLOAD_ERROR = "BANK_STATEMENT_UPLOAD_ERROR";
export const BANK_STATEMENT_UPLOAD_RESULT_RESET = "BANK_STATEMENT_UPLOAD_RESULT_RESET";
export const BANK_STATEMENT_UPLOAD_SUCCESS = "BANK_STATEMENT_UPLOAD_SUCCESS";

export const BANK_STATEMENT_DELETE = "BANK_STATEMENT_DELETE";
export const BANK_STATEMENT_DELETE_ERROR = "BANK_STATEMENT_DELETE_ERROR";
export const BANK_STATEMENT_DELETE_SUCCESS = "BANK_STATEMENT_DELETE_SUCCESS";

