import * as actionTypes from "./actionTypes";

const initialState = {
	suppliers: null,
	loading: false,
	error: null
}

export default function supplierReducer(state = initialState, action) {
	switch (action.type) {
		case actionTypes.SUPPLIER_GET_ALL:
			return {
				...state,
				error: null
			}
		case actionTypes.SUPPLIER_GET_BY_ID:
			return {
				...state,
				error: null
			}
		case actionTypes.SUPPLIER_GET_IN_PROCESS:
			return {
				...state,
				loading: true
			}
		case actionTypes.SUPPLIER_GET_SUCCESS:
			return {
				...state,
				loading: false,
				suppliers: action.suppliers
			}
		case actionTypes.SUPPLIER_GET_ERROR:
			return {
				...state,
				loading: false,
				error: action.error
			}
		case actionTypes.SUPPLIER_CREATE:
			return {
				...state,
				error: null
			}
		case actionTypes.SUPPLIER_CREATE_ERROR:
			return {
				...state,
				loading: false,
				error: action.error
			}
		default:
			return state;
	}
}