import * as actionTypes from "./actionTypes";

export const supplierInvoiceCreate = (supplierInvoice, history) => {
	return {
		type: actionTypes.SUPPLIER_INVOICE_CREATE,
		supplierInvoice: supplierInvoice, 
		history: history
	}
}

export const supplierInvoiceCreateError = (thisError) => {
	return {
		type: actionTypes.SUPPLIER_INVOICE_CREATE_ERROR,
		error: thisError
	}
}

export const supplierInvoiceCreateSuccess = () => {
	return {
		type: actionTypes.SUPPLIER_INVOICE_CREATE_SUCCESS
	}
}

export const supplierInvoiceDelete = (id, history) => {
	return {
		type: actionTypes.SUPPLIER_INVOICE_DELETE,
		id: id,
		history: history
	}
}

export const supplierInvoiceDeleteError = (thisError) => {
	return {
		type: actionTypes.SUPPLIER_INVOICE_DELETE_ERROR,
		error: thisError
	}
}

export const supplierInvoiceDeleteSuccess = () => {
	return {
		type: actionTypes.SUPPLIER_INVOICE_DELETE_SUCCESS
	}
}

export const supplierInvoiceGetAll = () => {
	return {
		type: actionTypes.SUPPLIER_INVOICE_GET_ALL
	}
}

export const supplierInvoiceGetUnpaid = () => {
	return {
		type: actionTypes.SUPPLIER_INVOICE_GET_UNPAID
	}
}

export const supplierInvoiceGetUnallocated = () => {
	return {
		type: actionTypes.SUPPLIER_INVOICE_GET_UNALLOCATED
	}
}

export const supplierInvoiceGetById = (id) => {
	return {
		type: actionTypes.SUPPLIER_INVOICE_GET_BY_ID,
		id: id
	}
}

export const supplierInvoiceGetError = (thisError) => {
	return {
		type: actionTypes.SUPPLIER_INVOICE_GET_ERROR,
		error: thisError
	}
}

export const supplierInvoiceGetSuccess = (data) => {
	return {
		type: actionTypes.SUPPLIER_INVOICE_GET_SUCCESS,
		supplierInvoices: data
	}
}

export const supplierInvoiceGetTotalsForUnallocated = (selectedInvoices, currencyCode, date) => {
	return {
		type: actionTypes.SUPPLIER_INVOICE_GET_TOTALS_FOR_UNALLOCATED,
		selectedInvoices: selectedInvoices,
		currencyCode: currencyCode,
		date: date
	}
}

export const supplierInvoiceGetTotalsSuccess = (totals) => {
	return {
		type: actionTypes.SUPPLIER_INVOICE_GET_TOTALS_SUCCESS,
		totals: totals
	}
}