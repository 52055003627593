import { call, put, takeLatest } from 'redux-saga/effects';

import * as actions from "./actions";
import * as actionTypes from './actionTypes';
import * as endpointsBackend from '../../definitions/endpoints/endpoints-backend';
import * as endpointsFrontend from '../../definitions/endpoints/endpoints-frontend';
import * as rest from "../../helpers/restHelper";
import * as sagaHelper from "../../helpers/sagaHelper";

function* createSupplierInvoiceSaga({ supplierInvoice, history }) {
	try {
		yield call(
			rest.post,
			endpointsBackend.SUPPLIER_INVOICE_CREATE_OR_UPDATE,
			supplierInvoice
		);
		if (supplierInvoice.paidAmountOutsideCRM == supplierInvoice.invoiceAmount) {
			history.push(endpointsFrontend.SUPPLIER_INVOICE_BROWSE_ALL);
		} else {
			history.push(endpointsFrontend.SUPPLIER_INVOICE_BROWSE_UNPAID);
		}
		yield put(actions.supplierInvoiceCreateSuccess());
	} catch (e) {
		yield put(actions.supplierInvoiceCreateError(e));
	}
}

function* deleteSupplierInvoiceSaga({ id, history }) {
	try {
		yield call(
			rest.del,
			endpointsBackend.SUPPLIER_INVOICE_GET_BY_ID.replace("{supplierInvoiceId}", id)
		);
		history.push(endpointsFrontend.SUPPLIER_INVOICE_BROWSE_ALL);
		yield put(actions.supplierInvoiceDeleteSuccess());
	} catch (e) {
		yield put(actions.supplierInvoiceDeleteError(e));
	}
}

function* getSupplierInvoicesSaga(url) {
	try {
		const response = yield call(
			rest.get,
			url
		);

		yield put(actions.supplierInvoiceGetSuccess(sagaHelper.getEntriesFromResponseData(response.data)));
	} catch (e) {
		yield put(actions.supplierInvoiceGetError(e));
	}
}

function* getAllSupplierInvoicesSaga() {
	yield call(getSupplierInvoicesSaga, endpointsBackend.SUPPLIER_INVOICE_GET_ALL);
}

function* getUnpaidSupplierInvoicesSaga() {
	yield call(getSupplierInvoicesSaga, endpointsBackend.SUPPLIER_INVOICE_GET_UNPAID);
}

function* getUnallocatedSupplierInvoicesSaga() {
	yield call(getSupplierInvoicesSaga, endpointsBackend.SUPPLIER_INVOICE_GET_UNALLOCATED);
}

function* getSupplierInvoiceByIdSaga({ id }) {
	try {
		const response = yield call(
			rest.get,
			endpointsBackend.SUPPLIER_INVOICE_GET_BY_ID.replace("{supplierInvoiceId}", id)
		);

		yield put(actions.supplierInvoiceGetSuccess([{ ...response.data }]));
	} catch (e) {
		yield put(actions.supplierInvoiceGetError(e));
	}
}

function* getSupplierInvoiceTotalsForUnallocatedSaga({ selectedInvoices, currencyCode, date }) {
	try {
		const response = yield call(
			rest.post,
			endpointsBackend.SUPPLIER_INVOICE_GET_UNALLOCATED_TOTALS,
			{
				idList: selectedInvoices,
				currencyCode: currencyCode,
				date: date
			}
		);
		yield put(actions.supplierInvoiceGetTotalsSuccess(response.data));
	} catch(e) {

	}
}

function* supplierInvoiceSaga() {
	yield (takeLatest(actionTypes.SUPPLIER_INVOICE_GET_ALL, getAllSupplierInvoicesSaga));
	yield (takeLatest(actionTypes.SUPPLIER_INVOICE_GET_UNPAID, getUnpaidSupplierInvoicesSaga));
	yield (takeLatest(actionTypes.SUPPLIER_INVOICE_GET_UNALLOCATED, getUnallocatedSupplierInvoicesSaga));
	yield (takeLatest(actionTypes.SUPPLIER_INVOICE_GET_BY_ID, getSupplierInvoiceByIdSaga));
	yield (takeLatest(actionTypes.SUPPLIER_INVOICE_CREATE, createSupplierInvoiceSaga));
	yield (takeLatest(actionTypes.SUPPLIER_INVOICE_DELETE, deleteSupplierInvoiceSaga));
	yield (takeLatest(actionTypes.SUPPLIER_INVOICE_GET_TOTALS_FOR_UNALLOCATED, getSupplierInvoiceTotalsForUnallocatedSaga))
}

export default supplierInvoiceSaga;