import React, { Component } from "react";
import { Buffer } from 'buffer';
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import { connect } from "react-redux";
import MetaTags from 'react-meta-tags';

import * as actionsBankAccount from '../../store/BankAccount/actions';
import * as actionsCRMBankImplementation from '../../store/CRMBankImplementation/actions';
import * as actionsCurrency from '../../store/Currency/actions';
import Breadcrumbs from "../../components/Common/Breadcrumb";
import * as config from '../../config';
import * as editFormControls from '../../helpers/editFormControls';
import * as inputSelectUtils from '../../helpers/inputSelectUtils';

class BankAccountEdit extends Component {

	constructor(props) {
		super(props);
		this.state = {
			id: "",
			currencyId: "",
			currencyCode: "",
			externalId: "",
			accountNo: "",
			bankSwiftCode: "",
			bankAlternativeCode: "",
			bankName: "",
			corrAccountNo: "",
			corrSwiftCode: "",
			corrBankName: "",
			additionalInfo: "",
			accountCustomType: "",
			useDirectChannel: false,
			signPaymentsInCRM: false,
			requiredSignatoryCount: 1,

			certFilename: "",
			hasCertPassword: false,
			gatewayAgreementId: "",
			useTestEnvironment: false,
			sftpUsername: "",
			hostFingerprint: "",
			encryptionKeyFilename: "",
			signatureKeyFilename: "",
			hasSignatureKeyPassword: false,
			allowTodayStatements: false,
			allowYesterdayStatements: false,
			allowEarlierStatements: false,

			useCorrBank: false,

			hasCertFile: false,
			hasEncryptionKeyFile: false,
			hasSignatureKeyFile: false,

			uploadCertFile: false,
			uploadEncryptionKeyFile: false,
			uploadSignatureKeyFile: false,
			changeCertPassword: false,
			changeSignatureKeyPassword: false,
			certPassword: "",
			signatureKeyPassword: "",
			certFile: null,
			encryptionKeyFile: null,
			signatureKeyFile: null,

			currentBankImplementation: null,
			changed: false,
			currencies: [],
			bankAccounts: [],
			errors: {}
		}
		this.onChange = this.onChange.bind(this);
		this.onSubmit = this.onSubmit.bind(this);
	}

	crmTemplateToRegex(crmTemplate) {
		return crmTemplate.replaceAll("?", ".").replaceAll("*", ".*?");
	}

	detectBankImplementation() {
		let matchingImplementation = null;
		if (!this.props.crmBankImplementations || !this.props.crmBankImplementations.length) {
			return;
		}
		const matchesByIBAN = this.props.crmBankImplementations.filter(item => this.state.accountNo.match(this.crmTemplateToRegex(item.ibanTemplate)));
		if (matchesByIBAN && matchesByIBAN.length) {
			matchingImplementation = matchesByIBAN[0];
		} else {
			const matchesBySWIFTCode = this.props.crmBankImplementations.filter(item => this.state.bankSwiftCode.match(this.crmTemplateToRegex(item.bankSwiftCode)));
			if (matchesBySWIFTCode && matchesBySWIFTCode.length) {
				matchingImplementation = matchesBySWIFTCode[0];
			}
		}
		this.setState({
			currentBankImplementation: matchingImplementation
		});
	}

	onChange(e) {
		const thisObjectName = e.target.name;
		let thisValue;
		if (e.target.type == "checkbox") {
			thisValue = e.target.checked;
		} else if (e.target.type == "file") {
			thisValue = e.target.files[0];
		} else {
			thisValue = e.target.value;
		}
		this.setState({
			changed: true,
			[thisObjectName]: thisValue
		});
	}

	uploadedFileToAttachmentAsArray(uploadedFile) {
		return new Promise((resolve, reject) => {
			if (uploadedFile) {
				uploadedFile.arrayBuffer().then(response => {
					resolve({
						filename: uploadedFile.name,
						contentType: uploadedFile.type,
						contents: Buffer.from(response, 'binary').toString('base64')
					});
				});
				// See also: https://developer.mozilla.org/en-US/docs/Web/API/File
			} else {
				resolve(null);
			}
		});
	}

	onSubmit(e) {
		e.preventDefault();		// prevent the form from refreshing
		this.uploadedFileToAttachmentAsArray(this.state.certFile).then(certFile => {
			this.uploadedFileToAttachmentAsArray(this.state.encryptionKeyFile).then(encryptionKeyFile => {
				this.uploadedFileToAttachmentAsArray(this.state.signatureKeyFile).then(signatureKeyFile => {
					const newOrUpdatedBankAccount = {
						id: this.state.id ? this.state.id : null,

						currencyId: this.state.currencyId,
						currencyCode: this.state.currencyCode,
						externalId: this.state.externalId,
						accountNo: this.state.accountNo,
						bankSwiftCode: this.state.bankSwiftCode,
						bankAlternativeCode: this.state.bankAlternativeCode,
						bankName: this.state.bankName,
						corrAccountNo: this.state.useCorrBank ? this.state.corrAccountNo : "",
						corrSwiftCode: this.state.useCorrBank ? this.state.corrSwiftCode : "",
						corrBankName: this.state.useCorrBank ? this.state.corrBankName : "",
						additionalInfo: this.state.additionalInfo,
						accountCustomType: this.state.accountCustomType,
						useDirectChannel: this.state.useDirectChannel,
						signPaymentsInCRM: this.state.signPaymentsInCRM,
						requiredSignatoryCount: this.state.requiredSignatoryCount,
						allowTodayStatements: this.state.allowTodayStatements,
						allowYesterdayStatements: this.state.allowYesterdayStatements,
						allowEarlierStatements: this.state.allowEarlierStatements,

						gatewayAgreementId: this.state.gatewayAgreementId,
						useTestEnvironment: this.state.useTestEnvironment,
						sftpUsername: this.state.sftpUsername,
						hostFingerprint: this.state.hostFingerprint,

						certPassword: ((!this.state.hasCertPassword || this.state.changeCertPassword) ? this.state.certPassword : null),
						signatureKeyPassword: ((!this.state.hasSignatureKeyPassword || this.state.changeSignatureKeyPassword) ? this.state.signatureKeyPassword : null),

						certFile: certFile,
						encryptionKeyFile: encryptionKeyFile,
						signatureKeyFile: signatureKeyFile
					}
					this.setState({
						changed: false
					})
					this.props.onCreateBankAccount(newOrUpdatedBankAccount, this.props.history);
				});
			});
		});
	}

	onTestBankAccount = () => {
		this.props.onTestBankAccount(this.state.id)
	}

	onDeleteBankAccount = () => {
		if (window.confirm("Are you sure you want to delete this Bank account?")) {
			this.props.onDeleteBankAccount(this.state.id, this.props.history);
		}
	}

	componentDidMount() {
		const { id } = this.props.match.params;
		if (id) {
			// As of 2024.01.25, CRM bank implementations are included in the result of getBankAccountById()
			this.props.onGetBankAccountById(id);
		} else {
			this.props.onGetCRMBankImplementations();
		}
		this.props.onGetCurrencies();
	}

	componentDidUpdate(prevProps, prevState, snapshot) {

		if (prevProps.error !== this.props.error) {
			if (this.props.error) {
				this.setState({
					errors: this.props.error
				});
			} else {
				this.setState({
					errors: ""
				})
			}
		}

		if (prevProps.currencies !== this.props.currencies) {
			if (!this.state.currencyId && this.props.currencies[0]) {
				this.setState({
					currencyId: this.props.currencies[0].id
				})
			}
		}

		if (prevProps.bankAccounts !== this.props.bankAccounts) {
			if (this.props.bankAccounts && this.props.bankAccounts[0]) {

				this.setState({
					id: this.props.bankAccounts[0].id,
					currencyId: this.props.bankAccounts[0].currencyId,
					currencyCode: this.props.bankAccounts[0].currencyCode,
					externalId: this.props.bankAccounts[0].externalId,
					accountNo: this.props.bankAccounts[0].accountNo,
					bankSwiftCode: this.props.bankAccounts[0].bankSwiftCode,
					bankAlternativeCode: this.props.bankAccounts[0].bankAlternativeCode,
					bankName: this.props.bankAccounts[0].bankName,
					corrAccountNo: this.props.bankAccounts[0].corrAccountNo,
					corrSwiftCode: this.props.bankAccounts[0].corrSwiftCode,
					corrBankName: this.props.bankAccounts[0].corrBankName,
					additionalInfo: this.props.bankAccounts[0].additionalInfo,
					accountCustomType: this.props.bankAccounts[0].accountCustomType,
					useDirectChannel: this.props.bankAccounts[0].useDirectChannel,
					signPaymentsInCRM: this.props.bankAccounts[0].signPaymentsInCRM,
					requiredSignatoryCount: this.props.bankAccounts[0].requiredSignatoryCount,
					certFilename: this.props.bankAccounts[0].certFilename,
					hasCertPassword: this.props.bankAccounts[0].hasCertPassword,
					gatewayAgreementId: this.props.bankAccounts[0].gatewayAgreementId,
					useTestEnvironment: this.props.bankAccounts[0].useTestEnvironment,
					sftpUsername: this.props.bankAccounts[0].sftpUsername,
					hostFingerprint: this.props.bankAccounts[0].hostFingerprint,
					encryptionKeyFilename: this.props.bankAccounts[0].encryptionKeyFilename,
					signatureKeyFilename: this.props.bankAccounts[0].signatureKeyFilename,
					hasSignatureKeyPassword: this.props.bankAccounts[0].hasSignatureKeyPassword,
					allowTodayStatements: this.props.bankAccounts[0].allowTodayStatements,
					allowYesterdayStatements: this.props.bankAccounts[0].allowYesterdayStatements,
					allowEarlierStatements: this.props.bankAccounts[0].allowEarlierStatements,

					hasCertFile: (this.props.bankAccounts[0].certFilename && this.props.bankAccounts[0].certFilename.length),
					hasEncryptionKeyFile: (this.props.bankAccounts[0].encryptionKeyFilename && this.props.bankAccounts[0].encryptionKeyFilename.length),
					hasSignatureKeyFile: (this.props.bankAccounts[0].signatureKeyFilename && this.props.bankAccounts[0].signatureKeyFilename.length),
					useCorrBank: ((this.props.bankAccounts[0].corrAccountNo && this.props.bankAccounts[0].corrAccountNo.length)
						&& ((this.props.bankAccounts[0].corrSwiftCode && this.props.bankAccounts[0].corrSwiftCode.length)
							|| (this.props.bankAccounts[0].corrBankName && this.props.bankAccounts[0].corrBankName.length)))
				});
			} else {
				this.setState({
					id: "",
					currencyId: "",
					currencyCode: "",
					externalId: "",
					accountNo: "",
					bankSwiftCode: "",
					bankAlternativeCode: "",
					bankName: "",
					corrAccountNo: "",
					corrSwiftCode: "",
					corrBankName: "",
					additionalInfo: "",
					accountCustomType: "",
					useDirectChannel: false,
					signPaymentsInCRM: false,
					requiredSignatoryCount: 1,
					allowTodayStatements: "",
					allowYesterdayStatements: "",
					allowEarlierStatements: "",

					certFilename: "",
					hasCertPassword: false,
					gatewayAgreementId: "",
					useTestEnvironment: false,
					sftpUsername: "",
					hostFingerprint: "",
					encryptionKeyFilename: "",
					signatureKeyFilename: "",
					hasSignatureKeyPassword: false,

					changeCertPassword: false,
					changeSignatureKeyPassword: false,
					certPassword: "",
					signatureKeyPassword: "",
					certFile: null,
					encryptionKeyFile: null,
					signatureKeyFile: null,
					useCorrBank: false
				});
			}
		}

		if ((prevState.accountNo !== this.state.accountNo && this.state.accountNo && this.state.accountNo.length)
			|| (prevState.bankSwiftCode !== this.state.bankSwiftCode && this.state.bankSwiftCode && this.state.bankSwiftCode.length)) {

			this.detectBankImplementation();
		}

		if (prevState.currentBankImplementation !== this.state.currentBankImplementation && this.state.currentBankImplementation) {
			const currentBankImplementation = this.state.currentBankImplementation;
			this.setState({
				bankName: currentBankImplementation.bankName,
				bankSwiftCode: currentBankImplementation.bankSwiftCode.replaceAll("*", "")
			});
		}

		if ((prevProps.testSuccess !== this.props.testSuccess) || (prevProps.error !== this.props.error)) {
			window.scrollBy(0, -document.body.scrollHeight);
		}

	}

	render() {
		const pageTitle = (this.state.id || this.props.loading ? "Edit" : "Create") + " bank account | " + config.AppName;;
		const breadcrumbsTitle = "Bank accounts";
		const breadcrumbsItem = (this.state.id || this.props.loading ? "Edit" : "New") + " bank account";

		const currencyOptions = inputSelectUtils.generateOptionsFromData(this.props.currencies, currencyRow => (currencyRow.code + (currencyRow.name ? " (" + currencyRow.name + ")" : "")));

		const bankAlternativeCodeContents = (this.state.currentBankImplementation && this.state.currentBankImplementation.bankAlternativeCodeContents && this.state.currentBankImplementation.bankAlternativeCodeContents.length > 1)
			? this.state.currentBankImplementation.bankAlternativeCodeContents : null;

		const additionalInfoContents = (this.state.currentBankImplementation && this.state.currentBankImplementation.additionalInfoContents && this.state.currentBankImplementation.additionalInfoContents.length > 1)
			? this.state.currentBankImplementation.additionalInfoContents : null;

		const directChannelFields = (this.state.currentBankImplementation && this.state.currentBankImplementation.directChannelFields && this.state.currentBankImplementation.directChannelFields.length > 1)
			? this.state.currentBankImplementation.directChannelFields : "";

		const directChannelSubform =
			<React.Fragment>

				{editFormControls.checkboxControl("useTestEnvironment", "Use test environment", this.onChange, this.state.useTestEnvironment)}
				{editFormControls.checkboxControl("signPaymentsInCRM", "Sign payments in CashManager", this.onChange, this.state.signPaymentsInCRM)}
				{this.state.signPaymentsInCRM && editFormControls.numberControlWithMinMax("requiredSignatoryCount", "Required signatory count", this.onChange, this.state.requiredSignatoryCount, 1, 1, 3)}

				{directChannelFields.includes("certFilename;") && editFormControls.fileControl("certFile", "Certificate file", this.onChange, this.state.certFilename, this.state.hasCertFile, this.state.uploadCertFile, (uploadCertFile) => this.setState({ uploadCertFile: uploadCertFile }))}
				{directChannelFields.includes("certPassword;") && editFormControls.passwordControl("certPassword", "Certificate password", this.onChange, this.state.certPassword, "", this.state.hasCertPassword, this.state.changeCertPassword, (changeCertPassword) => this.setState({ changeCertPassword: changeCertPassword }))}
				{directChannelFields.includes("gatewayAgreementId;") && editFormControls.textControl("gatewayAgreementId", "Gateway agreement ID", this.onChange, this.state.gatewayAgreementId, "Enter Gateway agreement ID")}
				{directChannelFields.includes("sftpUsername;") && editFormControls.textControl("sftpUsername", "SFTP username", this.onChange, this.state.sftpUsername, "Enter SFTP username")}
				{directChannelFields.includes("hostFingerprint;") && editFormControls.textControl("hostFingerprint", "Host fingerprint (optional)", this.onChange, this.state.hostFingerprint)}
				{directChannelFields.includes("encryptionKeyFilename;") && editFormControls.fileControl("encryptionKeyFile", "Encryption key file", this.onChange, this.state.encryptionKeyFilename, this.state.hasEncryptionKeyFile, this.state.uploadEncryptionKeyFile, (uploadEncryptionKeyFile) => this.setState({ uploadEncryptionKeyFile: uploadEncryptionKeyFile }))}
				{directChannelFields.includes("signatureKeyFilename;") && editFormControls.fileControl("signatureKeyFile", "Signature key file", this.onChange, this.state.signatureKeyFilename, this.state.hasSignatureKeyFile, this.state.uploadSignatureKeyFile, (uploadSignatureKeyFile) => this.setState({ uploadSignatureKeyFile: uploadSignatureKeyFile }))}
				{directChannelFields.includes("signatureKeyPassword;") && editFormControls.passwordControl("signatureKeyPassword", "Signature key password", this.onChange, this.state.signatureKeyPassword, "", this.state.hasSignatureKeyPassword, this.state.changeSignatureKeyPassword, (changeSignatureKeyPassword) => this.setState({ changeSignatureKeyPassword: changeSignatureKeyPassword }))}
				{directChannelFields.includes("allowTodayStatements;") && editFormControls.checkboxControl("allowTodayStatements", "Allow today statements", this.onChange, this.state.allowTodayStatements)}
				{directChannelFields.includes("allowYesterdayStatements;") && editFormControls.checkboxControl("allowYesterdayStatements", "Allow yesterday statements", this.onChange, this.state.allowYesterdayStatements)}
				{directChannelFields.includes("allowEarlierStatements;") && editFormControls.checkboxControl("allowEarlierStatements", "Allow earlier statements", this.onChange, this.state.allowEarlierStatements)}

			</React.Fragment>

		const editForm = (
			<Row>
				<Col lg="12">
					<Card>
						<CardBody>
							<form
								className="outer-repeater"
								onSubmit={this.onSubmit}
							>
								<div data-repeater-list="outer-group" className="outer">
									<div data-repeater-item className="outer">

										{editFormControls.hiddenValueControl("id", this.onChange, this.state.id)}
										{editFormControls.selectControl("currencyId", "Currency", this.onChange, this.state.currencyId, currencyOptions)}
										{editFormControls.textControl("accountNo", "Account No.", this.onChange, this.state.accountNo)}
										{editFormControls.textControl("bankSwiftCode", "SWIFT-BIC code", this.onChange, this.state.bankSwiftCode, "Enter SWIFT-BIC code")}
										{bankAlternativeCodeContents && editFormControls.textControl("bankAlternativeCode", bankAlternativeCodeContents, this.onChange, this.state.bankAlternativeCode, "Enter " + bankAlternativeCodeContents)}
										{editFormControls.textControl("bankName", "Bank name", this.onChange, this.state.bankName)}
										{editFormControls.checkboxControl("useCorrBank", "Use correspondent account", this.onChange, this.state.useCorrBank)}
										{this.state.useCorrBank && editFormControls.textControl("corrAccountNo", "Correspondent account No.", this.onChange, this.state.corrAccountNo)}
										{this.state.useCorrBank && editFormControls.textControl("corrSwiftCode", "Correspondent SWIFT-BIC code", this.onChange, this.state.corrSwiftCode)}
										{this.state.useCorrBank && editFormControls.textControl("corrBankName", "Correspondent bank name", this.onChange, this.state.corrBankName)}
										{additionalInfoContents && editFormControls.textControl("additionalInfo", additionalInfoContents, this.onChange, this.state.additionalInfo, "Enter " + additionalInfoContents)}
										{/* 2024.01.24: Do we need accountCustomType at all? As of now it is used neither in BankAccount nor in SupplierBankAccount
										 {editFormControls.textControl("accountCustomType", "Account custom type", this.onChange, this.state.accountCustomType)} */}

										{directChannelFields.length ? editFormControls.checkboxControl("useDirectChannel", "Use Direct Channel", this.onChange, this.state.useDirectChannel) : null}
										{this.state.useDirectChannel && directChannelSubform}

										{editFormControls.textControl("externalId", "External ID", this.onChange, this.state.externalId)}
									</div>
								</div>
								<Row className="justify-content-end">
									<Col lg="10">
										{editFormControls.saveButton(this.props.saving, this.state.id)}
										{" "}
										{this.state.useDirectChannel ? editFormControls.testButton(this.props.testing, this.onTestBankAccount, this.state.changed) : null}
										{" "}
										{editFormControls.copyToClipboardButton(
											"ID: " + this.state.id + "\n"
											+ "Currency: " + this.state.currencyCode + "\n"
											+ "External ID: " + this.state.externalId + "\n"
											+ "Account No.: " + this.state.accountNo + "\n"
											+ "Bank SWIFT code: " + this.state.bankSwiftCode + "\n"
											+ "Bank alternative code: " + this.state.bankAlternativeCode + "\n"
											+ "Bank name: " + this.state.bankName + "\n"
											+ "Corr account No.: " + this.state.corrAccountNo + "\n"
											+ "Corr swift code: " + this.state.corrSwiftCode + "\n"
											+ "Corr bank name: " + this.state.corrBankName + "\n"
											+ "Additional info: " + this.state.additionalInfo + "\n"
											+ "Account custom type: " + this.state.accountCustomType + "\n"
											+ "Use direct channel: " + this.state.useDirectChannel + "\n"
											+ "Sign payments in CashManager: " + this.state.signPaymentsInCRM + "\n"
											+ "Required signatory count: " + this.state.requiredSignatoryCount + "\n"
											+ "Cert filename: " + this.state.certFilename + "\n"
											+ "Gateway agreement ID: " + this.state.gatewayAgreementId + "\n"
											+ "Use test environment: " + this.state.useTestEnvironment + "\n"
											+ "SFTP username: " + this.state.sftpUsername + "\n"
											+ "Host fingerprint: " + this.state.hostFingerprint + "\n"
											+ "Encryption certificate filename: " + this.state.encryptionCertificateFilename + "\n"
										)}
										{" "}
										{editFormControls.deleteButton(this.props.deleting, this.onDeleteBankAccount, this.state.id)}
									</Col>
								</Row>
							</form>
						</CardBody>
					</Card>
				</Col>
			</Row>
		);

		return (
			<React.Fragment>
				<div className="page-content">
					<MetaTags>
						<title>{pageTitle}</title>
					</MetaTags>
					<Container fluid>
						<Breadcrumbs title={breadcrumbsTitle} breadcrumbItem={breadcrumbsItem} />

						{editFormControls.errorAlert(this.props.error)}

						{editFormControls.testSuccessAlert(!this.state.changed && this.props.testSuccess)}

						{editFormControls.formLoadingSpinner(this.props.loading)}

						{!this.props.loading ? editForm : null}

					</Container>
				</div>
			</React.Fragment>
		)
	}
}

const mapStateToProps = ({ bankAccount, crmBankImplementation, currency }) => ({
	currencies: currency.currencies,
	bankAccounts: bankAccount.bankAccounts,
	crmBankImplementations:
		bankAccount.bankAccounts && bankAccount.bankAccounts.length
			? bankAccount.bankAccounts[0].crmBankImplementations
			: crmBankImplementation.crmBankImplementations && crmBankImplementation.crmBankImplementations.length
				? crmBankImplementation.crmBankImplementations
				: [],
	deleting: bankAccount.deleting,
	error: bankAccount.error,
	loading: bankAccount.loading,
	saving: bankAccount.saving,
	testing: bankAccount.testing,
	testSuccess: bankAccount.testSuccess
})

const mapDispatchToProps = dispatch => ({
	onCreateBankAccount: (bankAccount, history) => dispatch(actionsBankAccount.bankAccountCreate(bankAccount, history)),
	onDeleteBankAccount: (id, history) => dispatch(actionsBankAccount.bankAccountDelete(id, history)),
	onGetCRMBankImplementations: () => dispatch(actionsCRMBankImplementation.crmBankImplementationGetAll()),
	onGetBankAccountById: (id) => dispatch(actionsBankAccount.bankAccountGetById(id)),
	onGetCurrencies: () => dispatch(actionsCurrency.currencyGetAll()),
	onTestBankAccount: (id) => dispatch(actionsBankAccount.bankAccountTestConnection(id))
})


export default connect(
	mapStateToProps,
	mapDispatchToProps
)(BankAccountEdit);
