import { call, put, takeLatest } from 'redux-saga/effects';

import * as actions from "./actions";
import * as actionTypes from './actionTypes';
import * as endpointsFrontend from '../../definitions/endpoints/endpoints-frontend';
import * as endpointsBackend from '../../definitions/endpoints/endpoints-backend';
import * as rest from "../../helpers/restHelper";
import { downloadHelper } from '../../helpers/downloadHelper';
import { getEntriesFromResponseData } from '../../helpers/sagaHelper';

function* copyCustomerInvoiceSaga({ customerInvoiceId, history }) {
	try {
		const response = yield call(
			rest.post,
			endpointsBackend.CUSTOMER_INVOICE_COPY.replace("{customerInvoiceId}", customerInvoiceId)
		);
		if (history) {
			history.push(endpointsFrontend.CUSTOMER_INVOICE_EDIT.replace(":id", response.data.id));
		}
		yield put(actions.customerInvoiceCopySuccess(response.data));
	} catch (e) {
		yield put(actions.customerInvoiceCopyError(e));
	}
}

function* createCustomerInvoiceSaga({ customerInvoice, history }) {
	try {
		const response = yield call(
			rest.post,
			endpointsBackend.CUSTOMER_INVOICE_CREATE_OR_UPDATE,
			customerInvoice
		);
		if (history) {
			history.push(endpointsFrontend.CUSTOMER_INVOICE_BROWSE_ALL);
		}
		yield put(actions.customerInvoiceCreateSuccess(response.data));
	} catch (e) {
		yield put(actions.customerInvoiceCreateError(e));
	}
}

function* deleteCustomerInvoiceSaga({ id, history }) {
	try {
		yield call(
			rest.del,
			endpointsBackend.CUSTOMER_INVOICE_GET_BY_ID.replace("{customerInvoiceId}", id)
		);
		yield put(actions.customerInvoiceDeleteSuccess());
		history.push(endpointsFrontend.CUSTOMER_INVOICE_BROWSE_ALL);
	} catch (e) {
		yield put(actions.customerInvoiceDeleteError(e));
	}
}

function* deleteCustomerInvoiceListSaga({ customerInvoicesToDelete }) {
	try {
		yield call(
			rest.del,
			endpointsBackend.CUSTOMER_INVOICE_CREATE_OR_UPDATE,
			{ idList: customerInvoicesToDelete }
		);
		yield put(actions.customerInvoiceDeleteSuccess());
		yield call(getAllCustomerInvoicesSaga);
	} catch (e) {
		yield put(actions.customerInvoiceDeleteError(e));
	}
}

function* getAllCustomerInvoicesSaga() {
	try {
		const response = yield call(
			rest.get,
			endpointsBackend.CUSTOMER_INVOICE_GET_ALL
		);

		yield put(actions.customerInvoiceGetSuccess(getEntriesFromResponseData(response.data)));
	} catch (e) {
		yield put(actions.customerInvoiceGetError(e));
	}
}

function* getUnallocatedCustomerInvoicesSaga() {
	try {
		const response = yield call(
			rest.get,
			endpointsBackend.CUSTOMER_INVOICE_GET_UNALLOCATED
		);

		yield put(actions.customerInvoiceGetSuccess(getEntriesFromResponseData(response.data)));
	} catch (e) {
		yield put(actions.customerInvoiceGetError(e));
	}
}

function* getOverdueCustomerInvoicesSaga() {
	try {
		const response = yield call(
			rest.get,
			endpointsBackend.CUSTOMER_INVOICE_GET_OVERDUE
		);

		yield put(actions.customerInvoiceGetSuccess(getEntriesFromResponseData(response.data)));
	} catch (e) {
		yield put(actions.customerInvoiceGetError(e));
	}
}

function* getCustomerInvoiceByIdSaga({ id }) {
	try {
		const response = yield call(
			rest.get,
			endpointsBackend.CUSTOMER_INVOICE_GET_BY_ID.replace("{customerInvoiceId}", id)
		);

		yield put(actions.customerInvoiceGetSuccess([{ ...response.data }]));
	} catch (e) {
		yield put(actions.customerInvoiceGetError(e));
	}
}

function* getAllCustomerInvoiceLinesSaga({ customerInvoiceId }) {
	yield put(actions.customerInvoiceLineGetInProcess());
	try {
		const response = yield call(
			rest.get,
			endpointsBackend.CUSTOMER_INVOICE_LINE_GET_ALL_FOR_INVOICE.replace("{customerInvoiceId}", customerInvoiceId)
		);

		const entries = [];
		for (let key in response.data) {
			const periodStart = response.data[key].periodStart;
			const periodEnd = response.data[key].periodEnd;
			entries.push({
				...response.data[key],
				period: (periodStart ? periodStart : "") + (periodStart && periodEnd ? " - " : "") + (periodEnd ? periodEnd : "")
			});
		}

		yield put(actions.customerInvoiceLineGetSuccess(entries));
	} catch (e) {
		yield put(actions.customerInvoiceLineGetError(e));
	}
}

function* getCustomerInvoiceLineByIdSaga({ id }) {
	yield put(actions.customerInvoiceLineGetInProcess());
	try {
		const response = yield call(
			rest.get,
			endpointsBackend.CUSTOMER_INVOICE_LINE_GET_BY_ID.replace("{customerInvoiceLineId}", id)
		);
		yield put(actions.customerInvoiceLineGetSuccess([{ ...response.data }]));
	} catch (e) {
		yield put(actions.customerInvoiceLineGetError(e));
	}
}

function* createCustomerInvoiceLineSaga({ customerInvoiceLine, history }) {
	try {
		const response = yield call(
			rest.post,
			endpointsBackend.CUSTOMER_INVOICE_LINE_CREATE_OR_UPDATE,
			customerInvoiceLine
		);
		if (history) {
			history.push(endpointsFrontend.CUSTOMER_INVOICE_EDIT.replace(":id", customerInvoiceLine.customerInvoiceId));
		}

		yield put(actions.customerInvoiceLineCreateSuccess(response.data));
	} catch (e) {
		yield put(actions.customerInvoiceLineCreateError(e));
	}
}

function* moveUpCustomerInvoiceLineSaga({ id }) {
	try {
		const response = yield call(
			rest.post,
			endpointsBackend.CUSTOMER_INVOICE_LINE_MOVE_UP.replace("{customerInvoiceLineId}", id)
		);

		const entries = [];
		for (let key in response.data) {
			entries.push({
				...response.data[key]
			});
		}

		yield put(actions.customerInvoiceLineMoveSuccess(entries));
	} catch (e) {
		yield put(actions.customerInvoiceLineGetError(e));
	}
}

function* moveDownCustomerInvoiceLineSaga({ id }) {
	try {
		const response = yield call(
			rest.post,
			endpointsBackend.CUSTOMER_INVOICE_LINE_MOVE_DOWN.replace("{customerInvoiceLineId}", id)
		);

		const entries = [];
		for (let key in response.data) {
			entries.push({
				...response.data[key]
			});
		}

		yield put(actions.customerInvoiceLineMoveSuccess(entries));
	} catch (e) {
		yield put(actions.customerInvoiceLineGetError(e));
	}
}

function* copyCustomerInvoiceLineSaga({ idList }) {
	try {
		const response = yield call(
			rest.post,
			endpointsBackend.CUSTOMER_INVOICE_LINE_COPY,
			{ idList: idList }
		);

		const entries = [];
		let customerInvoiceId = null;
		for (let key in response.data) {
			entries.push({
				...response.data[key]
			});
			if (!customerInvoiceId) {
				customerInvoiceId = response.data[key].customerInvoiceId;
			}
		}

		yield put(actions.customerInvoiceLineMoveSuccess(entries));
		if (customerInvoiceId) {
			yield call(getCustomerInvoiceByIdSaga, { id: customerInvoiceId });
		}
	} catch (e) {
		yield put(actions.customerInvoiceLineGetError(e));
	}
}

function* deleteCustomerInvoiceLineSaga({ id, history }) {
	try {
		yield call(
			rest.del,
			endpointsBackend.CUSTOMER_INVOICE_LINE_GET_BY_ID.replace("{customerInvoiceLineId}", id)
		);
		history.goBack();
		//history.push(endpointsFrontend.CUSTOMER_INVOICE_LINE_BROWSE);
	} catch (e) {
		yield put(actions.customerInvoiceLineCreateError(e));
	}
}

function* deleteCustomerInvoiceLineListSaga({ idList }) {
	try {
		const response = yield call(
			rest.del,
			endpointsBackend.CUSTOMER_INVOICE_LINE_CREATE_OR_UPDATE,
			{ idList: idList }
		);

		const entries = [];
		let customerInvoiceId = null;
		for (let key in response.data) {
			entries.push({
				...response.data[key]
			});
			if (!customerInvoiceId) {
				customerInvoiceId = response.data[key].customerInvoiceId;
			}
		}

		yield put(actions.customerInvoiceLineDeleteSuccess(entries));
		if (customerInvoiceId) {
			yield call(getCustomerInvoiceByIdSaga, { id: customerInvoiceId });
		}
	} catch (e) {
		yield put(actions.customerInvoiceLineDeleteError(e));
	}
}

function* printCustomerInvoiceToPDFSaga({ id }) {
	try {
		const response = yield call(
			rest.get,
			endpointsBackend.CUSTOMER_INVOICE_PRINT.replace("{customerInvoiceId}", id)
		);
		yield call(downloadHelper, response);

		yield put(actions.customerInvoicePrintToPDFSuccess());
	} catch (e) {
		yield put(actions.customerInvoicePrintToPDFError(e));
	}
}

function* printCustomerInvoiceListToPDFSaga({ idList }) {
	try {
		const response = yield call(
			rest.post,
			endpointsBackend.CUSTOMER_INVOICE_PRINT_LIST,
			{ idList: idList }
		);
		yield call(downloadHelper, response);

		yield put(actions.customerInvoicePrintToPDFSuccess());
	} catch (e) {
		yield put(actions.customerInvoicePrintToPDFError(e));
	}
}

function* sendCustomerInvoiceListSaga({ customerInvoicesToSend }) {
	try {
		yield call(
			rest.post,
			endpointsBackend.CUSTOMER_INVOICE_SEND_LIST,
			{ idList: customerInvoicesToSend }
		);
		yield put(actions.customerInvoiceSendSuccess());
		yield call(getUnallocatedCustomerInvoicesSaga);
	} catch (e) {
		yield put(actions.customerInvoiceSendError(e));
	}
}


function* sendRemindersCustomerInvoiceListSaga({ customerInvoicesToSendReminders }) {
	try {
		yield call(
			rest.post,
			endpointsBackend.CUSTOMER_INVOICE_SEND_OVERDUE_REMINDERS,
			{ idList: customerInvoicesToSendReminders }
		);
		yield put(actions.customerInvoiceSendRemindersSuccess());
		yield call(getOverdueCustomerInvoicesSaga);
	} catch (e) {
		yield put(actions.customerInvoiceSendRemindersError(e));
	}
}


function* customerInvoiceSaga() {
	yield (takeLatest(actionTypes.CUSTOMER_INVOICE_CREATE, createCustomerInvoiceSaga));
	yield (takeLatest(actionTypes.CUSTOMER_INVOICE_COPY, copyCustomerInvoiceSaga));
	yield (takeLatest(actionTypes.CUSTOMER_INVOICE_DELETE, deleteCustomerInvoiceSaga));
	yield (takeLatest(actionTypes.CUSTOMER_INVOICE_DELETE_LIST, deleteCustomerInvoiceListSaga));
	yield (takeLatest(actionTypes.CUSTOMER_INVOICE_GET_ALL, getAllCustomerInvoicesSaga));
	yield (takeLatest(actionTypes.CUSTOMER_INVOICE_GET_OVERDUE, getOverdueCustomerInvoicesSaga));
	yield (takeLatest(actionTypes.CUSTOMER_INVOICE_GET_UNALLOCATED, getUnallocatedCustomerInvoicesSaga));
	yield (takeLatest(actionTypes.CUSTOMER_INVOICE_GET_BY_ID, getCustomerInvoiceByIdSaga));
	yield (takeLatest(actionTypes.CUSTOMER_INVOICE_PRINT_TO_PDF, printCustomerInvoiceToPDFSaga));
	yield (takeLatest(actionTypes.CUSTOMER_INVOICE_PRINT_LIST_TO_PDF, printCustomerInvoiceListToPDFSaga));
	yield (takeLatest(actionTypes.CUSTOMER_INVOICE_SEND_LIST, sendCustomerInvoiceListSaga));
	yield (takeLatest(actionTypes.CUSTOMER_INVOICE_SEND_REMINDERS_LIST, sendRemindersCustomerInvoiceListSaga));

	yield (takeLatest(actionTypes.CUSTOMER_INVOICE_LINE_GET_ALL, getAllCustomerInvoiceLinesSaga));
	yield (takeLatest(actionTypes.CUSTOMER_INVOICE_LINE_GET_BY_ID, getCustomerInvoiceLineByIdSaga));
	yield (takeLatest(actionTypes.CUSTOMER_INVOICE_LINE_CREATE, createCustomerInvoiceLineSaga));
	yield (takeLatest(actionTypes.CUSTOMER_INVOICE_LINE_DELETE, deleteCustomerInvoiceLineSaga));
	yield (takeLatest(actionTypes.CUSTOMER_INVOICE_LINE_DELETE_LIST, deleteCustomerInvoiceLineListSaga));
	yield (takeLatest(actionTypes.CUSTOMER_INVOICE_LINE_MOVE_UP, moveUpCustomerInvoiceLineSaga));
	yield (takeLatest(actionTypes.CUSTOMER_INVOICE_LINE_MOVE_DOWN, moveDownCustomerInvoiceLineSaga));
	yield (takeLatest(actionTypes.CUSTOMER_INVOICE_LINE_COPY, copyCustomerInvoiceLineSaga));
}

export default customerInvoiceSaga;