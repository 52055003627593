import { call, put, takeLatest } from 'redux-saga/effects';

import * as actions from "./actions";
import * as actionTypes from './actionTypes';
import * as endpointsBackend from '../../definitions/endpoints/endpoints-backend';
import * as endpointsFrontend from '../../definitions/endpoints/endpoints-frontend';
import * as rest from "../../helpers/restHelper";
import * as sagaHelper from '../../helpers/sagaHelper';

function* createCustomerSaga({ customer, history }) {
	try {
		yield call(
			rest.post,
			endpointsBackend.CUSTOMER_CREATE_OR_UPDATE,
			customer
		);
		yield put(actions.customerCreateSuccess());
		history.push(endpointsFrontend.CUSTOMER_BROWSE);
	} catch (e) {
		yield put(actions.customerCreateError(e));
	}
}

function* deleteCustomerSaga({ id, history }) {
	try {
		yield call(
			rest.del,
			endpointsBackend.CUSTOMER_GET_BY_ID.replace("{customerId}", id)
		);
		yield put(actions.customerDeleteSuccess());
		history.push(endpointsFrontend.CUSTOMER_BROWSE);
	} catch (e) {
		yield put(actions.customerDeleteError(e));
	}
}

function* getAllCustomersSaga() {
	try {
		const response = yield call(
			rest.get,
			endpointsBackend.CUSTOMER_GET_ALL
		);

		yield put(actions.customerGetSuccess(sagaHelper.getEntriesFromResponseData(response.data)));
	} catch (e) {
		yield put(actions.customerGetError(e));
	}
}

function* getCustomerByIdSaga({ id }) {
	try {
		const response = yield call(
			rest.get,
			endpointsBackend.CUSTOMER_GET_BY_ID.replace("{customerId}", id)
		);

		yield put(actions.customerGetSuccess([{ ...response.data }]));
	} catch (e) {
		yield put(actions.customerGetError(e));
	}
}

function* customerSaga() {
	yield (takeLatest(actionTypes.CUSTOMER_GET_ALL, getAllCustomersSaga));
	yield (takeLatest(actionTypes.CUSTOMER_GET_BY_ID, getCustomerByIdSaga));
	yield (takeLatest(actionTypes.CUSTOMER_CREATE, createCustomerSaga));
	yield (takeLatest(actionTypes.CUSTOMER_DELETE, deleteCustomerSaga));
}

export default customerSaga;