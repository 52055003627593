export const SUPPLIER_BANK_ACCOUNT_GET_ALL = "SUPPLIER_BANK_ACCOUNT_GET_ALL";
export const SUPPLIER_BANK_ACCOUNT_GET_BY_ID = "SUPPLIER_BANK_ACCOUNT_GET_BY_ID";
export const SUPPLIER_BANK_ACCOUNT_GET_BY_SUPPLIER = "SUPPLIER_BANK_ACCOUNT_GET_BY_SUPPLIER";
export const SUPPLIER_BANK_ACCOUNT_GET_IN_PROCESS = "SUPPLIER_BANK_ACCOUNT_GET_IN_PROCESS";
export const SUPPLIER_BANK_ACCOUNT_GET_ERROR = "SUPPLIER_BANK_ACCOUNT_GET_ERROR";
export const SUPPLIER_BANK_ACCOUNT_GET_SUCCESS = "SUPPLIER_BANK_ACCOUNT_GET_SUCCESS";

export const SUPPLIER_BANK_ACCOUNT_CREATE = "SUPPLIER_BANK_ACCOUNT_CREATE";
export const SUPPLIER_BANK_ACCOUNT_CREATE_ERROR = "SUPPLIER_BANK_ACCOUNT_CREATE_ERROR";
export const SUPPLIER_BANK_ACCOUNT_CREATE_SUCCESS = "SUPPLIER_BANK_ACCOUNT_CREATE_SUCCESS";

export const SUPPLIER_BANK_ACCOUNT_DELETE = "SUPPLIER_BANK_ACCOUNT_DELETE";

