import { call, put, takeLatest } from 'redux-saga/effects';

import * as actions from "./actions";
import * as actionTypes from './actionTypes';
import * as endpointsFrontend from '../../definitions/endpoints/endpoints-frontend';
import * as endpointsBackend from '../../definitions/endpoints/endpoints-backend';
import * as rest from "../../helpers/restHelper";
import * as sagaHelper from "../../helpers/sagaHelper";

function* getAllProductsSaga() {
	try {
		const response = yield call(
			rest.get,
			endpointsBackend.PRODUCT_GET_ALL
		);
		yield put(actions.productGetSuccess(sagaHelper.getEntriesFromResponseData(response.data)));
	} catch (e) {
		yield put(actions.productGetError(e));
	}
}

function* getProductByIdSaga({ id }) {
	try {
		const response = yield call(
			rest.get,
			endpointsBackend.PRODUCT_GET_BY_ID.replace("{productId}", id)
		);
		yield put(actions.productGetSuccess([{ ...response.data }]));
	} catch (e) {
		yield put(actions.productGetError(e));
	}
}

function* createProductSaga({ product, history }) {
	try {
		yield call(
			rest.post,
			endpointsBackend.PRODUCT_CREATE_OR_UPDATE,
			product
		);
		history.push(endpointsFrontend.PRODUCT_BROWSE);
	} catch (e) {
		yield put(actions.productCreateError(e));
	}
}

function* deleteProductSaga({ id, history }) {
	try {
		yield call(
			rest.del,
			endpointsBackend.PRODUCT_GET_BY_ID.replace("{productId}", id)
		);
		history.push(endpointsFrontend.PRODUCT_BROWSE);
	} catch (e) {
		yield put(actions.productCreateError(e));
	}
}

function* productSaga() {
	yield (takeLatest(actionTypes.PRODUCT_GET_ALL, getAllProductsSaga));
	yield (takeLatest(actionTypes.PRODUCT_GET_BY_ID, getProductByIdSaga));
	yield (takeLatest(actionTypes.PRODUCT_CREATE, createProductSaga));
	yield (takeLatest(actionTypes.PRODUCT_DELETE, deleteProductSaga));
}

export default productSaga;