import { numberFilter, textFilter } from 'react-bootstrap-table2-filter';		// docs: https://react-bootstrap-table.github.io/react-bootstrap-table2/

import * as formatUtils from 'helpers/formatUtils';
import * as tableUtils from 'helpers/tableUtils';

export const accountNo = {
    dataField: 'bankAccountNo',
    text: 'Account No.',
    filter: textFilter(),
    sort: true
};
export const currencyCode = {
    dataField: 'bankAccountCurrencyCode',
    text: 'Currency',
    filter: textFilter(),
    sort: true
};
export const openingDate = {
    dataField: 'openingDate',
    text: 'From',
    filter: textFilter(),
    sort: true
};
export const closingDate = {
    dataField: 'closingDate',
    text: 'To',
    filter: textFilter(),
    sort: true
};
export const allocatedAmountRatio = {
    dataField: 'allocatedAmountRatio',
    text: 'Allocated amount',
    align: 'center',
    filter: textFilter(),
    sort: true,
    sortFunc: (a, b, order) => tableUtils.sortFuncForRatio(a, b, order),
    formatter: (cell) => formatUtils.formatAmountRatioAsProgressBar(cell, "#a4e6a4")
};
export const allocatedRecordRatio = {
    dataField: 'allocatedRecordRatio',
    text: 'Allocated records',
    align: 'center',
    filter: textFilter(),
    sort: true,
    sortFunc: (a, b, order) => tableUtils.sortFuncForRatio(a, b, order),
    formatter: (cell) => formatUtils.formatRatioAsProgressBar(cell, "#a4e6a4")
};
export const confirmedRatio = {
	dataField: 'confirmedRatio',
	text: 'Confirmed records',
    align: 'center',
    filter: textFilter(),
    sort: true,
    sortFunc: (a, b, order) => tableUtils.sortFuncForRatio(a, b, order),
    formatter: (cell) => formatUtils.formatRatioAsProgressBar(cell, "#a4e6a4")
};
export const autoAllocationProgressRatio = {
    dataField: 'autoAllocationProgressRatio',
    text: 'Auto-allocation status',
    align: 'center',
    filter: textFilter(),
    sort: true,
    sortFunc: (a, b, order) => tableUtils.sortFuncForRatio(a, b, order),
    formatter: (cell, row) => formatUtils.formatRatioAsProgressBar(cell, (row.isBlocked ? "#f6a4a4" : "#a4e6a4"), "", (row.isBlocked ? "In progress" : "Complete"))
};