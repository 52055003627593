import { call, put, takeLatest } from 'redux-saga/effects';

import * as actions from "./actions";
import * as actionTypes from './actionTypes';
import * as rest from "../../helpers/restHelper";
import * as endpointsBackend from '../../definitions/endpoints/endpoints-backend';

function* createCompanySettingsSaga({ companySettings, history }) {
	try {
		yield call(
			rest.post,
			endpointsBackend.COMPANY_SETTINGS_CREATE_OR_UPDATE,
			companySettings
		);
		yield put(actions.companySettingsCreateSuccess());
	} catch (e) {
		yield put(actions.companySettingsCreateError(e));
	}
}

function* getCompanySettingsSaga() {
	try {
		const response = yield call(
			rest.get,
			endpointsBackend.COMPANY_SETTINGS_GET
		);

		yield put(actions.companySettingsGetSuccess(response.data));
	} catch (e) {
		yield put(actions.companySettingsGetError(e));
	}
}

function* companySettingsSaga() {
	yield (takeLatest(actionTypes.COMPANY_SETTINGS_GET, getCompanySettingsSaga));
	yield (takeLatest(actionTypes.COMPANY_SETTINGS_CREATE, createCompanySettingsSaga));
}

export default companySettingsSaga;