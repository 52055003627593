export const SUPPLIER_INVOICE_GET_ALL = "SUPPLIER_INVOICE_GET_ALL";
export const SUPPLIER_INVOICE_GET_UNPAID = "SUPPLIER_INVOICE_GET_UNPAID";
export const SUPPLIER_INVOICE_GET_UNALLOCATED = "SUPPLIER_INVOICE_GET_UNALLOCATED";
export const SUPPLIER_INVOICE_GET_BY_ID = "SUPPLIER_INVOICE_GET_BY_ID";
export const SUPPLIER_INVOICE_GET_ERROR = "SUPPLIER_INVOICE_GET_ERROR";
export const SUPPLIER_INVOICE_GET_SUCCESS = "SUPPLIER_INVOICE_GET_SUCCESS";
export const SUPPLIER_INVOICE_GET_TOTALS_FOR_UNALLOCATED = "SUPPLIER_INVOICE_GET_TOTALS_FOR_UNALLOCATED";
export const SUPPLIER_INVOICE_GET_TOTALS_SUCCESS = "SUPPLIER_INVOICE_GET_TOTALS_SUCCESS";

export const SUPPLIER_INVOICE_CREATE = "SUPPLIER_INVOICE_CREATE";
export const SUPPLIER_INVOICE_CREATE_ERROR = "SUPPLIER_INVOICE_CREATE_ERROR";
export const SUPPLIER_INVOICE_CREATE_SUCCESS = "SUPPLIER_INVOICE_CREATE_SUCCESS";

export const SUPPLIER_INVOICE_DELETE = "SUPPLIER_INVOICE_DELETE";
export const SUPPLIER_INVOICE_DELETE_ERROR = "SUPPLIER_INVOICE_DELETE_ERROR";
export const SUPPLIER_INVOICE_DELETE_SUCCESS = "SUPPLIER_INVOICE_DELETE_SUCCESS";
