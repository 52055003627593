import { call, put, takeEvery } from 'redux-saga/effects';

import * as actions from "./actions";
import * as rest from "../../helpers/restHelper";
import * as endpointsFrontend from '../../definitions/endpoints/endpoints-frontend';
import * as endpointsBackend from '../../definitions/endpoints/endpoints-backend';
import * as actionTypes from './actionTypes';

function* getAllSuppliersSaga() {
	yield put(actions.supplierGetInProcess());
	try {
		const response = yield call(
			rest.get,
			endpointsBackend.SUPPLIER_GET_ALL
		);

		const countries = [];
		for (let key in response.data) {
			countries.push({
				...response.data[key]
			});
		}

		yield put(actions.supplierGetSuccess(countries));
	} catch (e) {
		yield put(actions.supplierGetError(e));
	}
}

function* getSupplierByIdSaga({ id }) {
	yield put(actions.supplierGetInProcess());
	try {
		const response = yield call(
			rest.get,
			endpointsBackend.SUPPLIER_GET_BY_ID.replace("{supplierId}", id)
		);

		yield put(actions.supplierGetSuccess([{ ...response.data }]));
	} catch (e) {
		yield put(actions.supplierGetError(e));
	}
}

function* createSupplierSaga({ supplier, history }) {
	try {
		yield call(
			rest.post,
			endpointsBackend.SUPPLIER_CREATE_OR_UPDATE,
			supplier
		);
		history.push(endpointsFrontend.SUPPLIER_BROWSE);
	} catch (e) {
		yield put(actions.supplierCreateError(e));
	}
}

function* deleteSupplierSaga({ id, history }) {
	try {
		yield call(
			rest.del,
			endpointsBackend.SUPPLIER_GET_BY_ID.replace("{supplierId}", id)
		);
		history.push(endpointsFrontend.SUPPLIER_BROWSE);
	} catch (e) {
		yield put(actions.supplierCreateError(e));
	}
}

function* supplierSaga() {
	yield (takeEvery(actionTypes.SUPPLIER_GET_ALL, getAllSuppliersSaga));
	yield (takeEvery(actionTypes.SUPPLIER_GET_BY_ID, getSupplierByIdSaga));
	yield (takeEvery(actionTypes.SUPPLIER_CREATE, createSupplierSaga));
	yield (takeEvery(actionTypes.SUPPLIER_DELETE, deleteSupplierSaga));
}

export default supplierSaga;