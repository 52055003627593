import { call, put, takeLatest } from 'redux-saga/effects';

import * as actions from "./actions";
import * as actionTypes from './actionTypes';
import * as endpointsBackend from '../../definitions/endpoints/endpoints-backend';
import * as rest from "../../helpers/restHelper";
import * as sagaHelper from '../../helpers/sagaHelper';

function* createCounterSaga({ counter }) {
	try {
		const response = yield call(
			rest.post,
			endpointsBackend.COUNTER_CREATE_OR_UPDATE,
			counter
		);
		yield put(actions.counterCreateSuccess(response.data));
	} catch (e) {
		yield put(actions.counterCreateError(e));
	}
}

function* getAllCountersSaga() {
	try {
		const response = yield call(
			rest.get,
			endpointsBackend.COUNTER_GET_ALL
		);

		yield put(actions.counterGetSuccess(sagaHelper.getEntriesFromResponseData(response.data)));
	} catch (e) {
		yield put(actions.counterGetError(e));
	}
}

function* getAndUpdateCounterSaga({ counterType }) {
	try {
		const response = yield call(
			rest.get,
			endpointsBackend.COUNTER_GET_AND_UPDATE.replace("{counterType}", counterType)
		);

		yield put(actions.counterGetValueSuccess(response.data));
	} catch (e) {
		yield put(actions.counterGetValueError(e));
	}
}

function* getCounterByIdSaga({ id }) {
	try {
		const response = yield call(
			rest.get,
			endpointsBackend.COUNTER_GET_BY_ID.replace("{counterId}", id)
		);

		yield put(actions.counterGetSuccess([{ ...response.data }]));
	} catch (e) {
		yield put(actions.counterGetError(e));
	}
}

function* deleteCounterSaga({ id, history }) {
	try {
		const response = yield call(
			rest.del,
			endpointsBackend.COUNTER_GET_BY_ID.replace("{counterId}", id)
		);
		yield put(actions.counterDeleteSuccess(sagaHelper.getEntriesFromResponseData(response.data)));
	} catch (e) {
		yield put(actions.counterDeleteError(e));
	}
}

function* counterSaga() {
	yield (takeLatest(actionTypes.COUNTER_CREATE, createCounterSaga));
	yield (takeLatest(actionTypes.COUNTER_GET_ALL, getAllCountersSaga));
	yield (takeLatest(actionTypes.COUNTER_GET_AND_UPDATE, getAndUpdateCounterSaga));
	yield (takeLatest(actionTypes.COUNTER_GET_BY_ID, getCounterByIdSaga));
	yield (takeLatest(actionTypes.COUNTER_DELETE, deleteCounterSaga));
}

export default counterSaga;