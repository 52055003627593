import React, { Component } from "react";
import { Buffer } from 'buffer';
import BootstrapTable from 'react-bootstrap-table-next';
import filterFactory from 'react-bootstrap-table2-filter';		// docs: https://react-bootstrap-table.github.io/react-bootstrap-table2/
import paginationFactory from 'react-bootstrap-table2-paginator';
import MetaTags from 'react-meta-tags';
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Alert, Button, Card, CardBody, Col, Container, Row } from "reactstrap";

import * as actionsBankAccount from '../../store/BankAccount/actions';
import * as actionsBankStatement from '../../store/BankStatement/actions';
import Breadcrumbs from "components/Common/Breadcrumb";
import * as columnsBankStatement from 'definitions/columns/bankStatement';
import * as config from '../../config';
import * as editFormControls from '../../helpers/editFormControls';
import * as endpointsFrontend from '../../definitions/endpoints/endpoints-frontend';
import * as formatUtils from '../../helpers/formatUtils';
import RequestBankStatement from "components/Pages/RequestBankStatement";
import * as selectRowUtils from '../../helpers/selectRowUtils';

import "assets/scss/datatables.scss";
import 'react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css';

class BankStatementBrowse extends Component {

	constructor(props) {
		super(props);
		const defaultDate = new Date();					// today's date
		defaultDate.setDate(defaultDate.getDate() - 1);	// yesterday's date

		this.state = {
			blockedRecords: [],
			defaultDateFrom: defaultDate.toJSON().substring(0, 10) + " 00:00:00",
			defaultDateTo: defaultDate.toJSON().substring(0, 10) + " 00:00:00",
			file: null,
			selectedStatements: [],
			showRequestBankStatementForm: false
		}
		this.refreshTimer = null;
	}

	initData = () => {
		this.props.onGetBankStatements();
		if (!this.props.loadedBankAccounts) {
			this.props.onGetBankAccounts();
		}
	}

	componentDidMount() {
		if (this.props.selectedCompany) {
			this.initData();
		}
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		// 2023.11.27: fix: 231123-CompanyId
		if (prevProps.selectedCompany != this.props.selectedCompany) {
			this.initData();
		}

		if (prevProps.bankStatements != this.props.bankStatements && this.props.bankStatements) {
			const blockedRecords = this.props.bankStatements.filter(bankStatement => bankStatement.isBlocked);
			let latestDateTo, defaultDateFrom;
			for (let i in this.props.bankStatements) {
				if (!latestDateTo || latestDateTo < this.props.bankStatements[i].closingDate) {
					latestDateTo = this.props.bankStatements[i].closingDate
				}
			}
			if (latestDateTo) {
				if (latestDateTo.substring(11) === "23:59:59") {
					// latestDateTo time is 23:59:59 -> latest statement includes the whole day -> defaultDateFrom should start from the midnight of the next day
					defaultDateFrom = new Date(Date.parse(latestDateTo));
					defaultDateFrom.setSeconds(defaultDateFrom.getSeconds() + 1);
					defaultDateFrom.setMinutes(defaultDateFrom.getMinutes() - defaultDateFrom.getTimezoneOffset());
					defaultDateFrom = defaultDateFrom.toJSON().replace("T00:00:00.000Z", " 00:00:00");
				} else {
					// latestDateTo time is before the end of the day -> latest statement does not include the whole day -> defaultDateFrom should start from the start of the same day to include the whole day
					defaultDateFrom = latestDateTo.substring(0, 10) + " " + "00:00:00";
				}
				this.setState({
					blockedRecords: blockedRecords,
					defaultDateFrom: defaultDateFrom
				});
				if (defaultDateFrom > this.state.defaultDateTo) {
					this.setState({
						defaultDateTo: (new Date()).toJSON().substring(0, 10)
					});
				}
			}
			this.setState({
				selectedStatements: []
			})
		}

		if (!prevState.blockedRecords.length && this.state.blockedRecords.length && !this.refreshTimer) {
			this.refreshTimer = setInterval(() => {
				this.props.onRefreshBankStatements();
			}, 5000);
		}

		if (prevState.blockedRecords.length && !this.state.blockedRecords.length && this.refreshTimer) {
			clearInterval(this.refreshTimer);
			this.refreshTimer = null;
		}
	}

	componentWillUnmount() {
		clearInterval(this.refreshTimer);
		this.refreshTimer = null;
	}

	handleSubmit = event => {
		event.preventDefault();

		this.state.file.text().then(response => {
			this.props.onUploadBankStatement({
				filename: this.state.file.name,
				contentType: this.state.file.type,
				contents: Buffer.from(response).toString('base64')
			});
		});
	}

	handleChange = event => {
		this.setState({
			file: event.target.files[0]
		});
	}

	useDirectChannel = (bankAccounts) => {
		for (let key in bankAccounts) {
			if (bankAccounts[key].useDirectChannel) {
				return true;
			}
		}
		return false;
	}

	onDeleteBankStatements = () => {
		if (window.confirm("Are you sure you want to delete the selected statements?")) {
			this.props.onDeleteBankStatements(this.state.selectedStatements);
		}
	}

	render() {

		const pageTitle = "Bank Statements | " + config.AppName;
		const breadcrumbsTitle = "Bank statements";
		const breadcrumbsItem = "Browse bank statements";

		const loading = this.props.loading || this.props.loadingBankAccounts;
		const useDirectChannel = this.useDirectChannel(this.props.bankAccounts);

		const uploadResultAlertArray = [];
		if (this.props.uploadResult) {
			for (let i in this.props.uploadResult.rows) {
				const thisRow = this.props.uploadResult.rows[i];
				let uploadResultAlertText = "";
				let alertColor = "";

				if (thisRow.statementGotLoaded) {

					if (thisRow.hasCrossingStatement) {
						uploadResultAlertText = "Only records from "
							+ formatUtils.formatLocalDateTime(thisRow.adjustedOpeningDate) + " to "
							+ formatUtils.formatLocalDateTime(thisRow.adjustedClosingDate)
							+ " loaded, statement overlaps with already existing ["
							+ formatUtils.formatLocalDateTime(thisRow.crossingStatementOpeningDate) + " - "
							+ formatUtils.formatLocalDateTime(thisRow.crossingStatementClosingDate) + "]";
						alertColor = "warning";

					} else if (thisRow.hasAppendedStatement) {
						uploadResultAlertText = "Already existing statement ["
							+ formatUtils.formatLocalDateTime(thisRow.appendedStatementOriginalOpeningDate) + " - "
							+ formatUtils.formatLocalDateTime(thisRow.appendedStatementOriginalClosingDate)
							+ "] appended to ["
							+ formatUtils.formatLocalDateTime(thisRow.adjustedOpeningDate) + " - "
							+ formatUtils.formatLocalDateTime(thisRow.adjustedClosingDate) + "]";
						alertColor = "warning";

					} else {
						uploadResultAlertText = "Statement loaded successfully: "
							+ thisRow.bankAccountNo + " " + thisRow.bankAccountCurrencyCode + " ["
							+ formatUtils.formatLocalDateTime(thisRow.originalOpeningDate) + " - "
							+ formatUtils.formatLocalDateTime(thisRow.originalClosingDate)
							+ "]";
						alertColor = "success";
					}
				} else if (thisRow.bankAccountNotFound) {

					uploadResultAlertText = "Statement not loaded, bank account not found: "
						+ thisRow.bankAccountNo + " " + thisRow.bankAccountCurrencyCode;
					alertColor = "warning";

				} else {

					uploadResultAlertText = "Statement not loaded, overlaps with already existing ["
						+ formatUtils.formatLocalDateTime(thisRow.crossingStatementOpeningDate) + " - "
						+ formatUtils.formatLocalDateTime(thisRow.crossingStatementClosingDate) + "]";
					alertColor = "warning";
				}

				if (uploadResultAlertText.length && alertColor) {
					uploadResultAlertArray.push(
						<Alert
							color={alertColor}
							key={i}
							toggle={this.props.onResetUploadResult}
						>
							{uploadResultAlertText}
						</Alert>
					);
				}
			}
		}

		const uploadResultAlerts =
			<React.Fragment>
				{uploadResultAlertArray}
			</React.Fragment>

		const RequestBankStatementForm =
			<RequestBankStatement
				onClose={() => this.setState({ showRequestBankStatementForm: false })}
				defaultDateFrom={this.state.defaultDateFrom}
				defaultDateTo={this.state.defaultDateTo}
			/>

		let columns;
		if (this.state.blockedRecords.length) {
			columns = [
				columnsBankStatement.accountNo,
				columnsBankStatement.currencyCode,
				columnsBankStatement.openingDate,
				columnsBankStatement.closingDate,
				//columnsBankStatement.allocatedAmountRatio,
				columnsBankStatement.allocatedRecordRatio,
				columnsBankStatement.autoAllocationProgressRatio,
				columnsBankStatement.confirmedRatio
			];
		} else {
			columns = [
				columnsBankStatement.accountNo,
				columnsBankStatement.currencyCode,
				columnsBankStatement.openingDate,
				columnsBankStatement.closingDate,
				//columnsBankStatement.allocatedAmountRatio,
				columnsBankStatement.allocatedRecordRatio,
				columnsBankStatement.confirmedRatio
			];
		}

		const rowEvents = {
			onClick: (e, row, rowIndex) => {
				if (row.isBlocked) {
					window.alert("This statement is currently being automatically reconciled. Please wait until the automatic reconciliation has finished.");
				} else {
					this.props.history.push(endpointsFrontend.BANK_STATEMENT_RECONCILE.replace(":id", row.id));
				}
			}
		};

		const requestFromDCButton = (
			<Button
				color="primary"
				onClick={() => this.setState({ showRequestBankStatementForm: true })}
			>
				Request...
			</Button>
		)

		const receiveFromDCButton = (
			<Button
				color="primary"
				onClick={this.props.onReceiveBankStatements}
				disabled={this.props.receiving}
			>
				Receive
				{" "}
				{this.props.receiving ? editFormControls.buttonSpinner() : null}
			</Button>
		)

		const directChannelAndDeleteButtons =
			<React.Fragment>
				{useDirectChannel ? requestFromDCButton : null}
				{" "}
				{useDirectChannel ? receiveFromDCButton : null}
				{" "}
				{editFormControls.deleteButton(this.props.deleting, this.onDeleteBankStatements, true, !this.state.selectedStatements || !this.state.selectedStatements.length)}
			</React.Fragment>

		const selectRow = {
			mode: 'checkbox',

			onSelect: (row, isSelect, rowIndex, e) => {
				const selectedRows = selectRowUtils.getSelectedRowOnSelect(this.state.selectedStatements, row, isSelect);
				this.setState({
					selectedStatements: selectedRows
				});
			},

			onSelectAll: (isSelect, rows, e) => {
				const selectedRows = selectRowUtils.getSelectedRowOnSelectAll(this.state.selectedStatements, rows, isSelect)
				this.setState({
					selectedStatements: selectedRows
				});
			}
		};

		const table =
			<div className="mt-3">
				<BootstrapTable
					keyField='id'
					data={this.props.bankStatements}
					columns={columns}
					pagination={paginationFactory()}
					rowEvents={rowEvents}
					rowStyle={{ cursor: "pointer" }}
					filter={filterFactory()}
					defaultSorted={[{
						dataField: 'openingDate',
						order: 'desc'
					}]}
					selectRow={selectRow}
				/>
			</div>


		return (

			<React.Fragment>
				<div className="page-content">
					<MetaTags>
						<title>{pageTitle}</title>
					</MetaTags>

					{this.state.showRequestBankStatementForm && RequestBankStatementForm}

					<Container fluid>
						<Breadcrumbs title={breadcrumbsTitle} breadcrumbItem={breadcrumbsItem} />

						<Row>
							<Col lg="12">
								<Card>
									<CardBody>
										{editFormControls.errorAlert(this.props.error)}

										{uploadResultAlerts}

										{editFormControls.formLoadingSpinner(loading)}

										{!loading && this.props.bankStatements && table}

										{editFormControls.uploadSubform(this.state.file, this.props.uploading, this.handleChange, this.handleSubmit, directChannelAndDeleteButtons)}
									</CardBody>
								</Card>
							</Col>
						</Row>
					</Container>
				</div>
			</React.Fragment>
		);

	}
}

const mapStateToProps = ({ bankAccount, bankStatement, company }) => ({
	bankAccounts: bankAccount.bankAccounts,
	bankStatements: bankStatement.bankStatements,
	deleting: bankStatement.deleting,
	error: bankStatement.error,
	loadedBankAccounts: bankAccount.loaded,
	loading: bankStatement.loading,
	loadingBankAccounts: bankAccount.loading,
	receiving: bankStatement.receiving,
	selectedCompany: company.selectedCompany,
	uploading: bankStatement.uploading,
	uploadResult: bankStatement.uploadResult,
	error: bankStatement.error
})

const mapDispatchToProps = dispatch => ({
	onDeleteBankStatements: (idList) => dispatch(actionsBankStatement.bankStatementDelete(idList)),
	onGetBankAccounts: () => dispatch(actionsBankAccount.bankAccountGetAll()),
	onGetBankStatements: () => dispatch(actionsBankStatement.bankStatementGetAll()),
	onReceiveBankStatements: () => dispatch(actionsBankStatement.bankStatementReceive()),
	onRefreshBankStatements: () => dispatch(actionsBankStatement.bankStatementRefreshAll()),
	onResetUploadResult: () => dispatch(actionsBankStatement.bankStatementUploadResultReset()),
	onUploadBankStatement: (bankStatement) => dispatch(actionsBankStatement.bankStatementUpload(bankStatement))
})

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withRouter(BankStatementBrowse));
