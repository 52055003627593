import * as actionTypes from "./actionTypes";

export const supplierBankAccountGetAll = () => {
	return {
		type: actionTypes.SUPPLIER_BANK_ACCOUNT_GET_ALL
	}
}

export const supplierBankAccountGetById = (id) => {
	return {
		type: actionTypes.SUPPLIER_BANK_ACCOUNT_GET_BY_ID,
		id: id
	}
}

export const supplierBankAccountGetBySupplier = (id) => {
	return {
		type: actionTypes.SUPPLIER_BANK_ACCOUNT_GET_BY_SUPPLIER,
		id: id
	}
}

export const supplierBankAccountGetInProcess = () => {
	return {
		type: actionTypes.SUPPLIER_BANK_ACCOUNT_GET_IN_PROCESS
	}
}

export const supplierBankAccountGetError = (thisError) => {
	return {
		type: actionTypes.SUPPLIER_BANK_ACCOUNT_GET_ERROR,
		error: thisError
	}
}

export const supplierBankAccountGetSuccess = (data) => {
	return {
		type: actionTypes.SUPPLIER_BANK_ACCOUNT_GET_SUCCESS,
		supplierBankAccounts: data
	}
}

export const supplierBankAccountCreate = (supplierBankAccount, history) => {
	return {
		type: actionTypes.SUPPLIER_BANK_ACCOUNT_CREATE,
		supplierBankAccount: supplierBankAccount, 
		history: history
	}
}

export const supplierBankAccountCreateError = (thisError) => {
	return {
		type: actionTypes.SUPPLIER_BANK_ACCOUNT_CREATE_ERROR,
		error: thisError
	}
}

export const supplierBankAccountDelete = (id, history) => {
	return {
		type: actionTypes.SUPPLIER_BANK_ACCOUNT_DELETE,
		id: id,
		history: history
	}
}