import * as actionTypes from "./actionTypes";

export const supplierGetAll = () => {
	return {
		type: actionTypes.SUPPLIER_GET_ALL
	}
}

export const supplierGetById = (id) => {
	return {
		type: actionTypes.SUPPLIER_GET_BY_ID,
		id: id
	}
}

export const supplierGetInProcess = () => {
	return {
		type: actionTypes.SUPPLIER_GET_IN_PROCESS
	}
}

export const supplierGetError = (thisError) => {
	return {
		type: actionTypes.SUPPLIER_GET_ERROR,
		error: thisError
	}
}

export const supplierGetSuccess = (data) => {
	return {
		type: actionTypes.SUPPLIER_GET_SUCCESS,
		suppliers: data
	}
}

export const supplierCreate = (supplier, history) => {
	return {
		type: actionTypes.SUPPLIER_CREATE,
		supplier: supplier, 
		history: history
	}
}

export const supplierCreateError = (thisError) => {
	return {
		type: actionTypes.SUPPLIER_CREATE_ERROR,
		error: thisError
	}
}

export const supplierDelete = (id, history) => {
	return {
		type: actionTypes.SUPPLIER_DELETE,
		id: id,
		history: history
	}
}