export const COMPANY_SETTINGS_GET = "COMPANY_SETTINGS_GET";
export const COMPANY_SETTINGS_GET_ALL = "COMPANY_SETTINGS_GET_ALL";
export const COMPANY_SETTINGS_GET_BY_ID = "COMPANY_SETTINGS_GET_BY_ID";
export const COMPANY_SETTINGS_GET_ERROR = "COMPANY_SETTINGS_GET_ERROR";
export const COMPANY_SETTINGS_GET_SUCCESS = "COMPANY_SETTINGS_GET_SUCCESS";

export const COMPANY_SETTINGS_CREATE = "COMPANY_SETTINGS_CREATE";
export const COMPANY_SETTINGS_CREATE_ERROR = "COMPANY_SETTINGS_CREATE_ERROR";
export const COMPANY_SETTINGS_CREATE_SUCCESS = "COMPANY_SETTINGS_CREATE_SUCCESS";

export const COMPANY_SETTINGS_DELETE = "COMPANY_SETTINGS_DELETE";

