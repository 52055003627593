import { call, put, takeLatest } from 'redux-saga/effects';

import * as actions from "./actions";
import * as actionTypes from './actionTypes';
import * as endpointsBackend from '../../definitions/endpoints/endpoints-backend';
import * as endpointsFrontend from '../../definitions/endpoints/endpoints-frontend';
import * as rest from "../../helpers/restHelper";
import * as sagaHelper from '../../helpers/sagaHelper';

function* createCustomerAgreementSaga({ customerAgreement, history }) {
	try {
		yield call(
			rest.post,
			endpointsBackend.CUSTOMER_AGREEMENT_CREATE_OR_UPDATE,
			customerAgreement
		);
		yield put(actions.customerAgreementCreateSuccess());
		history.push(endpointsFrontend.CUSTOMER_AGREEMENT_BROWSE);
	} catch (e) {
		yield put(actions.customerAgreementCreateError(e));
	}
}

function* deleteCustomerAgreementSaga({ id, history }) {
	try {
		yield call(
			rest.del,
			endpointsBackend.CUSTOMER_AGREEMENT_GET_BY_ID.replace("{customerAgreementId}", id)
		);
		yield put(actions.customerAgreementDeleteSuccess());
		history.push(endpointsFrontend.CUSTOMER_AGREEMENT_BROWSE);
	} catch (e) {
		yield put(actions.customerAgreementDeleteError(e));
	}
}

function* getAllCustomerAgreementsSaga() {
	try {
		const response = yield call(
			rest.get,
			endpointsBackend.CUSTOMER_AGREEMENT_GET_ALL
		);

		yield put(actions.customerAgreementGetSuccess(sagaHelper.getEntriesFromResponseData(response.data)));
	} catch (e) {
		yield put(actions.customerAgreementGetError(e));
	}
}

function* getCustomerAgreementByIdSaga({ id }) {
	try {
		const response = yield call(
			rest.get,
			endpointsBackend.CUSTOMER_AGREEMENT_GET_BY_ID.replace("{customerAgreementId}", id)
		);

		yield put(actions.customerAgreementGetSuccess([{ ...response.data }]));
	} catch (e) {
		yield put(actions.customerAgreementGetError(e));
	}
}

function* getCustomerAgreementByCustomerIdSaga({ customerId }) {
	try {
		const response = yield call(
			rest.get,
			endpointsBackend.CUSTOMER_AGREEMENT_GET_BY_CUSTOMER_ID.replace("{customerId}", customerId)
		);

		yield put(actions.customerAgreementGetSuccess(sagaHelper.getEntriesFromResponseData(response.data)));
	} catch (e) {
		yield put(actions.customerAgreementGetError(e));
	}
}

function* customerAgreementSaga() {
	yield (takeLatest(actionTypes.CUSTOMER_AGREEMENT_CREATE, createCustomerAgreementSaga));
	yield (takeLatest(actionTypes.CUSTOMER_AGREEMENT_DELETE, deleteCustomerAgreementSaga));
	yield (takeLatest(actionTypes.CUSTOMER_AGREEMENT_GET_ALL, getAllCustomerAgreementsSaga));
	yield (takeLatest(actionTypes.CUSTOMER_AGREEMENT_GET_BY_ID, getCustomerAgreementByIdSaga));
	yield (takeLatest(actionTypes.CUSTOMER_AGREEMENT_GET_BY_CUSTOMER_ID, getCustomerAgreementByCustomerIdSaga));
}

export default customerAgreementSaga;