export const getEntriesFromResponseData = (responseData) => {
    const entries = [];
    for (let key in responseData) {
        entries.push({
            ...responseData[key]
        });
    }

    return entries;
}

