import * as actionTypes from "./actionTypes";

const initialState = {
	deleting: false,
	error: null,
	loading: false,
	loadingTotals: false,
	saving: false,
	supplierInvoices: null,
	totals: null
}

export default function supplierInvoiceReducer(state = initialState, action) {
	switch (action.type) {
		case actionTypes.SUPPLIER_INVOICE_CREATE:
			return {
				...state,
				error: null,
				saving: true
			}
		case actionTypes.SUPPLIER_INVOICE_CREATE_ERROR:
			return {
				...state,
				error: action.error,
				saving: false
			}
		case actionTypes.SUPPLIER_INVOICE_CREATE_SUCCESS:
			return {
				...state,
				error: null,
				saving: false
			}
		case actionTypes.SUPPLIER_INVOICE_DELETE:
			return {
				...state,
				deleting: true,
				error: null,
			}
		case actionTypes.SUPPLIER_INVOICE_DELETE_ERROR:
			return {
				...state,
				deleting: false,
				error: action.error
			}
		case actionTypes.SUPPLIER_INVOICE_DELETE_SUCCESS:
			return {
				...state,
				deleting: false,
				error: null,
			}
		case actionTypes.SUPPLIER_INVOICE_GET_ALL:
		case actionTypes.SUPPLIER_INVOICE_GET_UNPAID:
		case actionTypes.SUPPLIER_INVOICE_GET_UNALLOCATED:
		case actionTypes.SUPPLIER_INVOICE_GET_BY_ID:
			return {
				...state,
				error: null,
				loading: true
			}
		case actionTypes.SUPPLIER_INVOICE_GET_SUCCESS:
			return {
				...state,
				loading: false,
				supplierInvoices: action.supplierInvoices
			}
		case actionTypes.SUPPLIER_INVOICE_GET_ERROR:
			return {
				...state,
				loading: false,
				error: action.error
			}
		case actionTypes.SUPPLIER_INVOICE_GET_TOTALS_FOR_UNALLOCATED:
			return {
				...state,
				loadingTotals: true,
				totals: null
			}
		case actionTypes.SUPPLIER_INVOICE_GET_TOTALS_SUCCESS:
			return {
				...state,
				loadingTotals: false,
				totals: action.totals
			}
		default:
			return state;
	}
}