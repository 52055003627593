/* For a given date, get the ISO week number
 *
 * From https://stackoverflow.com/questions/6117814/get-week-of-year-in-javascript-like-in-php
 *
 * Algorithm is to find nearest thursday, it's year
 * is the year of the week number. Then get weeks
 * between that date and the first day of that year.
 *
 * Note that dates in one year can be weeks of previous
 * or next year, overlap is up to 3 days.
 *
 * e.g. 2014/12/29 is Monday in week  1 of 2015
 *      2012/1/1   is Sunday in week 52 of 2011
 */
export function getWeekNumber(d) {
    // Copy date so don't modify original
    d = new Date(Date.UTC(d.getFullYear(), d.getMonth(), d.getDate()));
    // Set to nearest Thursday: current date + 4 - current day number
    // Make Sunday's day number 7
    d.setUTCDate(d.getUTCDate() + 4 - (d.getUTCDay() || 7));
    // Get first day of year
    var yearStart = new Date(Date.UTC(d.getUTCFullYear(), 0, 1));
    // Calculate full weeks to nearest Thursday
    var weekNo = Math.ceil((((d - yearStart) / 86400000) + 1) / 7);
    // Return array of year and week number
    return weekNo;
}

export function compareAsDates(date1, date2) {
    date1 = new Date(date1.getFullYear(), date1.getMonth(), date1.getDate());
    date2 = new Date(date2.getFullYear(), date2.getMonth(), date2.getDate());
    if (date1.getTime() < date2.getTime()) {
        return -1;
    } else if (date1.getTime() > date2.getTime()) {
        return 1;
    } else {
        return 0;
    }
}

export function hoursToTimeInterval(hours) {
    const wholeHoursPassed = Math.floor(hours);
    const wholeMinutesPassed = Math.floor((hours - wholeHoursPassed) * 60);
    const wholeSecondsPassed = Math.floor((hours - wholeHoursPassed - wholeMinutesPassed / 60) * 3600);
    return {
        hours: wholeHoursPassed,
        minutes: wholeMinutesPassed,
        seconds: wholeSecondsPassed
    }
}

export function timeIntervalToHours(timeInterval) {
    return parseFloat(timeInterval.hours ? timeInterval.hours : 0)
        + parseFloat(timeInterval.minutes ? timeInterval.minutes / 60 : 0)
        + parseFloat(timeInterval.seconds ? timeInterval.seconds / 3600 : 0);
}

export function localDateToDate(localDate) {
    return new Date(localDate[0], localDate[1], localDate[2]);
}

export function convertToTimezone(datetimeObject, timezone) {
    const currentDateTime = datetimeObject.toLocaleString('en-US', { timeZone: timezone, year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit', hour12: false });
	const dateInUSFormat = currentDateTime.split(", ")[0];
	const dateParts = dateInUSFormat.split("/");
	const dateInISOFormat = dateParts[2] + "-" + dateParts[0] + "-" + dateParts[1];
	const time = currentDateTime.split(", ")[1];
	return {
		date: dateInISOFormat,
		time: time
	};
}

export function namedFieldsDatetimeObjectToDate(datetime) {
    return (datetime
        ? new Date(datetime.year, datetime.month - 1, datetime.day, datetime.hour, datetime.minute, datetime.second, datetime.millisecond)
        : null);

}